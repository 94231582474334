import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconStatistics: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M373.29,500.8q0-186.37,0-372.75c0-36.37,15.13-51.52,51.41-51.55q76.52-.06,153,0c32.65,0,49.47,16.5,49.48,49.4q.18,374.27.07,748.52c0,32.94-16.41,49-49.64,49.06q-77.28.12-154.56-.06c-34.53-.09-49.79-15.42-49.79-49.84Q373.27,687.17,373.29,500.8Z"/>
                <path d="M980,642.71q0,116,0,231.94c0,32.09-16.29,48.63-48.28,48.72q-78.82.23-157.67,0c-32.09-.08-48.37-16.45-48.39-48.64q-.09-232,0-463.9c0-36.79,15.57-52.08,52.89-52.16q75.79-.15,151.6,0c33.69.08,49.92,16.66,49.91,50.52Q980,526,980,642.71Z"/>
                <path d="M274.08,746.26c0,43.43.15,86.86-.06,130.29-.14,30.52-16,46.61-46.74,46.78q-80.29.46-160.59,0c-29.62-.16-46.38-16.48-46.48-45.89q-.45-130.29.07-260.58c.13-29.9,17.47-46.19,47.8-46.25q79.55-.15,159.08,0c30.18.09,46.77,16.63,46.92,46.88.21,42.93.05,85.85.05,128.78Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconStatistics;
