import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconUsers: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M240.53,877.65c4.08-59.85-8.74-119.52,15.18-176.3C279,646.08,319.77,609.51,376.54,591c5.7-1.86,13.93-.8,19.35,2,69.15,36.14,138,36,207.41.67,6.15-3.13,15.23-4.25,21.75-2.31,74,22,128.56,89.89,132.88,166.75,2.09,37.08,1,74.34,1.3,111.51,0,2.51-.82,5-1.34,8Z"/>
                <path d="M499.59,561.6c-91.11-.86-163.28-73.76-162.73-164.39.54-91.07,74.61-163.59,166-162.48,89.6,1.09,161.39,74.81,160.75,165C662.92,489.62,589.12,562.45,499.59,561.6Z"/>
                <path d="M284.15,449.39c-68.31,10-145.14-44.1-166.49-117.14C95,254.88,133.71,171.51,207.77,138c72.56-32.88,160.26-7.52,202.53,58.67C309.22,251.39,265.81,335,284.15,449.39Z"/>
                <path d="M714.53,449.32C738.94,330.84,674.24,236.51,590,196.87c18.42-41.1,95.2-93.91,181.79-67.36C852.12,154.15,902.24,241,882.9,323.3,861.52,414.22,775.89,457.43,714.53,449.32Z"/>
                <path d="M664.94,545.49c9.24-13.21,16.82-25,25.54-35.83,2.28-2.85,8.27-4.27,12.32-3.89,45.87,4.3,88.46-5.64,128.78-27.53,4.5-2.44,11.81-2,17.07-.38,75.3,22.78,130.06,92,134.11,171.81,1.93,37.9.33,76,.33,115.06H815.53c-3-47-16.63-91.09-43.68-130.48S709.21,565.35,664.94,545.49Z"/>
                <path d="M335.61,544.75c-92.52,44.92-142.71,117.44-151,219.38H16.46C20.05,709.54,9.07,654.9,26.54,602c20.65-62.49,63.4-103.51,125.55-124.15,5.71-1.9,14-1,19.34,1.82,38.06,20.35,78.28,29.94,121.34,26,10.11-.92,16.83.52,22.06,10.64C320,526.29,328.05,534.67,335.61,544.75Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconUsers;
