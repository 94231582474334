// #region imports
    // #region internal
    import SittingTray from './SittingTray';
    // #endregion internal
// #endregion imports



// #region module
const sitting = {
    SittingTray,
};
// #endregion module



// #region exports
export default sitting;
// #endregion exports
