import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconClassPrivateMethod: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M936.81,589q.38,93.62.53,187.34c0,26.93-13.33,55-34.88,73.34a86.85,86.85,0,0,1-12.16,8.57c-30,17.39-59.59,34.47-89.13,51.53s-59.08,34.08-88.62,51.09c-23.32,13.43-41.53,6.86-46-16.36a60.4,60.4,0,0,1-.73-11q-.31-94.18-.59-188.39c0-8.91,2.39-13.06,10.19-17.59l29.21-16.86c.24-5.53.39-10.95.64-16.42,2.45-52.73,41-119,85.37-149.79q4.77-3.3,9.49-6c46.48-26.83,89-12.34,96,40a164.88,164.88,0,0,1,1.22,21L925.73,583C934.7,577.86,936.78,579,936.81,589ZM748.48,685.34,859,621.53c-.28-8.42-.65-16.78-2.51-23.81-6.5-24.65-30.34-30.69-55-16.43-1.82,1.06-3.66,2.23-5.49,3.51-29,20.24-51.49,58.53-52.91,91.88-.17,3.67-.27,7.68-.34,11.9l5.74-3.24m67.95,79.16c9.94-15.15,10.87-32.49,2.52-39-4.58-3.55-11.16-2.92-17.78.9a46.47,46.47,0,0,0-15.33,15.42c-9.61,15.21-11.18,33.61-1.92,38.52,5.29,2.78,6.37,8,5.91,15.65-.22,3.74,0,7.27,0,10.89,0,6.17,0,12.37.06,18.5.1,8.87,3.48,10.57,11.54,5.92S813,821.06,813,812.18c0-12.2.07-24.39-.15-36.43-.07-3.88.8-7,3.58-11.25"/>
                <polygon points="401.56 453.28 482.27 801.46 163.6 879.67 401.56 453.28"/>
                <polygon points="492.83 32.25 638.73 661.67 537.06 686.61 442.86 280.24 165.11 777.89 62.66 803.04 492.83 32.25"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconClassPrivateMethod;
