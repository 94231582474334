import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconClass: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M819.24,804.13c-28.34,23-66.44,42.66-113.27,58.35-88.41,29.62-198.34,41.75-309.54,34.14C284.86,889,184.55,862.35,114,821.61c-35.88-20.71-62.17-43.91-78.15-69C20.12,728,15,702.64,20.62,677.26c5.87-26.45,23-51.4,51-74.16,28.33-23,66.44-42.66,113.26-58.35,88.41-29.62,198.34-41.74,309.54-34.14,111.56,7.64,211.87,34.27,282.43,75,35.88,20.72,62.17,43.91,78.15,69,15.73,24.65,20.85,50,15.23,75.4C864.41,756.42,847.24,781.38,819.24,804.13Zm-153.5,18.22C702.65,810,732.36,794.78,754,777.16c20.31-16.5,32.71-34.41,36.89-53.24,8.22-37.1-16.78-74.57-70.41-105.53C691.51,601.65,656,587.75,615,577.1c-40.18-10.44-83.81-17.33-129.69-20.47a785.75,785.75,0,0,0-136,2.34c-45.4,4.81-87.17,13.53-124.14,25.91s-66.61,27.57-88.29,45.19c-20.3,16.51-32.71,34.41-36.89,53.24-8.22,37.09,16.79,74.57,70.41,105.53,29,16.74,64.49,30.63,105.49,41.3,40.18,10.43,83.82,17.32,129.69,20.47a787.75,787.75,0,0,0,136-2.34c45.4-4.81,87.17-13.53,124.14-25.92"/>
                <path d="M871.12,610.49c-28.33,23-66.44,42.65-113.27,58.34C669.44,698.46,559.52,710.58,448.32,703c-111.58-7.64-211.88-34.28-282.44-75C130,607.25,103.71,584.06,87.74,559,72,534.36,66.88,509,72.51,483.62c5.86-26.46,23-51.41,51-74.17,28.32-23,66.43-42.65,113.26-58.35,88.41-29.62,198.33-41.74,309.53-34.13,111.58,7.63,211.88,34.27,282.45,75,35.87,20.7,62.16,43.9,78.13,68.94,15.73,24.65,20.86,50,15.24,75.4C916.29,562.78,899.12,587.73,871.12,610.49ZM593.48,654.62c45.4-4.81,87.18-13.53,124.14-25.92s66.62-27.57,88.29-45.19c20.31-16.5,32.72-34.41,36.89-53.24C851,493.18,826,455.7,772.41,424.74c-29-16.74-64.5-30.63-105.5-41.29C626.73,373,583.09,366.12,537.21,363a785.65,785.65,0,0,0-136,2.34C355.77,370.13,314,378.85,277,391.23s-66.61,27.57-88.28,45.19c-20.31,16.51-32.72,34.42-36.89,53.25-8.22,37.09,16.78,74.57,70.4,105.53,29,16.74,64.49,30.63,105.49,41.29,40.19,10.44,83.82,17.33,129.7,20.47a786.65,786.65,0,0,0,136-2.34"/>
                <path d="M928.35,396.9c-28.34,23-66.44,42.66-113.27,58.35-88.4,29.62-198.33,41.75-309.53,34.14-111.57-7.63-211.88-34.27-282.44-75C187.24,393.66,161,370.47,145,345.43c-15.73-24.65-20.86-50-15.23-75.4,5.86-26.45,23-51.41,51-74.16,28.33-23,66.44-42.66,113.27-58.35,88.41-29.62,198.34-41.75,309.53-34.14,111.57,7.63,211.88,34.27,282.44,75,35.88,20.71,62.17,43.91,78.14,69,15.73,24.65,20.85,50,15.24,75.4C973.51,349.19,956.35,374.14,928.35,396.9Zm-153.5,18.22c36.92-12.37,66.62-27.57,88.3-45.19,20.3-16.51,32.71-34.42,36.89-53.24,8.22-37.09-16.79-74.57-70.41-105.53-29-16.75-64.49-30.64-105.5-41.29C684,159.43,640.32,152.54,594.44,149.4a786.66,786.66,0,0,0-136,2.33c-45.41,4.81-87.17,13.53-124.14,25.92s-66.62,27.57-88.3,45.19c-20.3,16.5-32.71,34.42-36.88,53.24-8.22,37.1,16.77,74.57,70.4,105.53,29,16.75,64.5,30.64,105.5,41.29,40.17,10.45,83.81,17.33,129.69,20.47a785.65,785.65,0,0,0,136-2.34c45.41-4.81,87.18-13.52,124.14-25.91"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconClass;
