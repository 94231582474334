import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconRange: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <g>
                    <path d="M873.43,576.5H791C838.32,766.93,858,941.93,858.17,943.88a40,40,0,0,0,39.71,35.62,41.19,41.19,0,0,0,4.42-.24,40,40,0,0,0,35.39-44.13C936.82,927.23,918.48,763.52,873.43,576.5Z"/>
                    <path d="M256,423.5c28.32-83.48,59.11-152,91.9-204.44C397.06,140.39,449.09,100.5,502.52,100.5s105,39.74,153.61,118.12c32.58,52.49,63,121.17,91,204.88h84.09c-32-101-67.84-183.78-106.85-246.73C660.05,73.08,585.43,20.5,502.52,20.5S344.83,73,280,176.63c-39.39,63-75.71,145.82-108.31,246.87Z"/>
                    <path d="M128.6,576.5C82.51,763.42,63.25,927,62.34,934.9a40,40,0,0,0,35.13,44.33,38.4,38.4,0,0,0,4.65.27,40,40,0,0,0,39.68-35.4c.23-1.94,20.82-176.9,69.3-367.6Z"/>
                </g>
                <path d="M937.5,529.5h-125v-60h125Zm-225,0h-150v-60h150Zm-250,0h-150v-60h150Zm-250,0H62.5v-60h150Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconRange;
