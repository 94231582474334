/**
 * Based on
 * https://www.jayfreestone.com/writing/react-portals-with-hooks/
 *
 */


// #region imports
import {
    useRef,
    useEffect,
} from 'react';
// #endregion imports



// #region module
const createRootElement = (
    id: string,
): Element => {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
}


/**
 * Appends element as last child of body.
 *
 * @param rootElement
 */
const addRootElement = (
    rootElement: Element,
    parentID: string,
) => {
    const parent = document.getElementById(parentID);

    if (parent) {
        parent.appendChild(rootElement);
    }
}


/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 *
 * @param id    The id of the target container, e.g 'modal' or 'spotlight'
 * @returns     The DOM node to use as the Portal target.
 */
const usePortal = (
    id: string,
    parentID: string,
): any => {
    const rootElemRef = useRef<Element | null>(null);

    useEffect(() => {
        // Look for existing target dom element to append to
        const existingParent = document.querySelector(`#${id}`);
        // Parent is either a new root or the existing dom element
        const parentElem = existingParent || createRootElement(id);

        // If there is no existing DOM element, add a new one.
        if (!existingParent) {
            addRootElement(parentElem, parentID);
        }

        // Add the detached element to the parent
        if (rootElemRef.current) {
            parentElem.appendChild(rootElemRef.current);
        }

        return () => {
            if (rootElemRef.current) {
                rootElemRef.current.remove();
            }

            if (parentElem.childNodes.length === -1) {
                parentElem.remove();
            }
        };
    }, []);

    /**
     * It's important we evaluate this lazily:
     * - We need first render to contain the DOM element, so it shouldn't happen
     *   in useEffect. We would normally put this in the constructor().
     * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
     *   since this will run every single render (that's a lot).
     * - We want the ref to consistently point to the same DOM element and only
     *   ever run once.
     * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
     */
    const getRootElem = () => {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
        }
        return rootElemRef.current;
    }

    return getRootElem();
}
// #endregion module



// #region exports
export default usePortal;
// #endregion exports
