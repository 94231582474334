// #region imports
    // #region libraries
    import styled from 'styled-components';

    import {
        Theme,
    } from '@plurid/plurid-themes';
    // #endregion libraries


    // #region external
    import {
        fontFamilySansSerif,
    } from '#data/constants';
    // #endregion external
// #endregion imports



// #region module
export const StyledHeading1: any = styled.h1`
    color: ${props => props.theme.colorPrimary};
    font-family: ${fontFamilySansSerif};
    font-size: 2.2rem;
    margin: 1.4rem 0;
`;


export const StyledHeading2: any = styled.h2`
    color: ${props => props.theme.colorPrimary};
    font-family: ${fontFamilySansSerif};
    font-size: 2rem;
    margin: 1.2rem 0;
`;


export const StyledHeading3: any = styled.h3`
    color: ${props => props.theme.colorPrimary};
    font-family: ${fontFamilySansSerif};
    font-size: 1.8rem;
    margin: 1rem 0;
`;


export const StyledHeading4: any = styled.h4`
    color: ${props => props.theme.colorPrimary};
    font-family: ${fontFamilySansSerif};
    font-size: 1.6rem;
    margin: 1rem 0;
`;


export const StyledHeading5: any = styled.h5`
    color: ${props => props.theme.colorPrimary};
    font-family: ${fontFamilySansSerif};
    font-size: 1.4rem;
    margin: 1rem 0;
`;


export const StyledHeading6: any = styled.h5`
    color: ${props => props.theme.colorPrimary};
    font-family: ${fontFamilySansSerif};
    font-size: 1.2rem;
    margin: 1rem 0;
`;
// #endregion module
