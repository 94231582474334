import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconRotate: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M966.5,698.37c-53.6,129.32-202,280.26-426.87,289.34-208.76,8.43-399.1-119.3-475.33-318.83-73.14-191.45-13-414,148.5-546.21C305.51,46.79,412.51,9.61,532.27,12,651.61,14.39,756,56.85,847.49,136.18c2.61-19.55,5-37.37,7.36-55.2,1.46-11.07,3-22.13,4.27-33.23,1.09-9.73,6.18-14.73,16-13.58,24.26,2.85,48.51,5.8,72.7,9.14C957,44.57,960.9,50.83,959.61,61c-4.28,33.93-8.77,67.83-13.16,101.75-6.68,51.57-13.52,103.12-19.94,154.72-2.7,21.68-6.06,24.42-27.3,21.67Q773.06,322.8,646.86,306.86c-16.9-2.14-20.15-5.5-18.21-22,2.57-21.83,5.49-43.62,8.36-65.42,1.49-11.3,6.27-15.06,17.77-13.64,27,3.35,54,7,81,10.26,5.66.68,11.48.1,17.23.1l1.5-4.93c-22.14-13.58-43.41-28.91-66.56-40.47C476.39,65.07,225.74,178.68,164.83,407.36c-54.61,205,81.54,420.09,290.85,454.74,154.82,25.63,278.71-29,371.17-155.27,4.38-6,8.46-8.71,16-8.63,37,.4,74,.17,111.06.17Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconRotate;
