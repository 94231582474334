import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconOpacity: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M552.53,358l28.61-7.22-14.43-57.23-28.62,7.22A326.4,326.4,0,0,0,299.58,547.09l-6.28,28.84L351,588.5l6.28-28.84A268.5,268.5,0,0,1,552.53,358Z" />
                <path d="M697.23,300.54C660.54,160.73,533.1,57.29,381.94,57.29,202.22,57.29,56,203.51,56,383.24,56,535.63,161.14,663.92,302.69,699.4A326.07,326.07,0,0,0,618.06,942.71C797.78,942.71,944,796.49,944,616.77,944,466.93,841.14,336.45,697.23,300.54ZM115,383.24c0-147.19,119.73-266.92,266.91-266.92S648.86,236.05,648.86,383.24,529.12,650.15,381.94,650.15,115,530.4,115,383.24Zm503,500.44c-112.4,0-212.52-71.67-250.58-174.85,4.79.21,9.61.34,14.46.34a326.39,326.39,0,0,0,60.32-5.63L622.34,883.63C620.91,883.65,619.48,883.68,618.06,883.68Zm76.6-11.22L506.57,684.38a326.32,326.32,0,0,0,80.54-48.1L779.77,829a266.16,266.16,0,0,1-85.12,43.51Zm127.83-84.28L629.4,595.1A326.66,326.66,0,0,0,680.89,513L870.71,702.84a266.74,266.74,0,0,1-48.24,85.34Zm62-155.09L701.07,449.7a326.22,326.22,0,0,0,6.82-66.47q0-9.09-.51-18.06A267.6,267.6,0,0,1,885,616.78c0,5.47-.21,10.91-.54,16.31Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconOpacity;
