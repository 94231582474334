import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconIngress: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M900.86,499.37c-.94-187.75-131-348.25-315.75-384.21-131.11-25.53-247.25,9.1-346,99.64-10.77,9.87-20.94,20.16-36.54,21.7-21.56,2.13-40.29-7.94-49.44-26.68-9-18.41-5.39-39.87,9.89-55.27A475.09,475.09,0,0,1,301.15,57.47Q421.54,1.66,553.93,13.62c211.13,19.14,384.36,172.4,431,380.87C1042,649.4,888.46,905.1,634.3,970.73c-177.94,45.95-335.6,2.15-469.62-124.16-21.16-19.94-21.88-49.48-2.93-69.37,18.47-19.37,48.92-19.71,69.48.37,50.16,49,108.55,83.45,176.37,101.57,196,52.34,397.47-52.83,469.63-245C892.69,592.93,901.09,545,900.86,499.37Z"/>
                <path d="M631.11,551H539.53c0,17.53,0,34.35,0,51.17,0,20.46-1,21.42-21.91,21.46-29.47.06-29.45.06-29.47-29.68q0-20.19-.2-40.38c0-.63-.49-1.26-1.05-2.61-2.68-.21-5.71-.64-8.74-.64-40.73-.05-81.46.11-122.19-.19-7.27,0-10.78,2.14-13.42,9.26C313.6,637.3,240.6,681.77,156.3,673.32c-73.82-7.39-137-68.11-150.5-144.53C-13.43,419.58,76.51,318.15,186.92,325.6c76,5.13,127.75,44.64,155.8,115.21,2.39,6,5.11,8.39,11.83,8.37q165.69-.33,331.39-.16c15.09,0,18.53,3.13,18.55,17.89q.1,68.87,0,137.74c0,15.8-3.11,18.92-18.5,19-13.47.05-26.93.12-40.39,0-10.24-.13-14.39-4.23-14.49-14.51C631,590.13,631.11,571.18,631.11,551Zm-350-50.35C281,443,235.73,397,178.74,396.68S75,443.51,75.27,501c.26,55.8,47.7,102.38,103.79,101.9C235.31,602.4,281.23,556.42,281.14,500.68Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconIngress;
