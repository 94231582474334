import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconSystem: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M10,426.4c21.34-1.87,42.7-3.58,64-5.8,2.44-.25,5.7-2.79,6.69-5.09,9.11-21.12,18-42.34,26.37-63.76,1.06-2.7-.11-7.51-2-9.93-12.72-16-25.93-31.65-38.49-46.82l79-78.15c13.81,11.41,29.49,24,44.7,37.16,4.79,4.14,8.52,4.69,14.36,2.13,19.59-8.6,39.43-16.67,59.33-24.55,5.23-2.07,7.08-4.92,7.49-10.52C272.86,201,275,181,277,160.31H387.59c1.49,15,4.15,30.14,4.2,45.29,0,16.48,4.7,26.87,22.15,31.72,16.19,4.5,31.46,12.39,47,19.18,5,2.17,8.44,1.95,12.73-1.74,15.49-13.31,31.37-26.17,46.7-38.87l78.32,78.52c-12.32,14.9-24.9,30.6-38.07,45.78-4.21,4.85-4.53,8.66-2.05,14.38,8.53,19.63,16.6,39.47,24.53,59.35,1.89,4.74,4.34,6.78,9.67,7.2,20.35,1.6,40.66,3.82,61.6,5.88V537.53c-20.22,2-40.45,4.3-60.73,5.74-6.25.44-8.82,3.14-10.92,8.38-8,19.87-16.06,39.69-24.54,59.34-2.21,5.12-1.7,8.54,1.89,12.74,13.27,15.52,26.17,31.36,38.73,46.5l-78.5,78.35c-14.95-12.36-30.65-24.93-45.81-38.11-4.91-4.26-8.81-4.42-14.48-2-19.34,8.39-38.81,16.5-58.45,24.17-5.61,2.19-7.64,5.17-8.1,11.05-1.58,20-3.77,40-5.78,60.49H277c-1.94-20.19-4.13-40.42-5.66-60.69C271,738,269.23,735.12,264,733q-30.3-12-60.21-24.93c-5.12-2.22-8.54-1.79-12.8,1.85-15.53,13.26-31.41,26.12-47,38.95L65.69,670.54c12.63-15.23,25.26-30.89,38.42-46.07,4.18-4.82,4.63-8.68,2.1-14.46q-12.9-29.4-24.65-59.29c-2.09-5.26-4.91-7-10.45-7.42-20.41-1.49-40.77-3.62-61.15-5.5ZM190.6,481.83c-.33,77.92,63.14,141.93,141.06,142.28S473.49,561.33,474.24,483.2c.74-77.6-63-142.26-140.72-142.74A142,142,0,0,0,190.6,481.83Z"/>
                <path d="M713,980.52,632.13,946c6.75-15.9,13.08-31.47,20-46.78,2.24-5,2.11-8.49-1.32-12.91-12.57-16.16-24.93-32.49-36.88-49.11-3.6-5-7.48-5.85-13-5.09-16.09,2.22-32.23,4-49.57,6.15-3.58-29.39-7-57.78-10.63-87.28,16.47-2,32.21-4.28,48-5.71,7.19-.65,11.24-3.15,14-10.23,7.06-18.15,14.89-36,22.9-53.78,2.4-5.32,2.23-9-1.46-13.73-10.3-13-20-26.51-30.59-40.58L664,574.13c10.28,13.7,20.55,26.74,30,40.34,3.74,5.37,7.77,6.44,13.7,5.65,20.29-2.69,40.66-4.78,60.89-7.78,3.22-.48,7.21-3.87,8.64-7,7.1-15.29,13.47-30.93,20.48-47.35l80.93,34.49c-6.37,14.94-12.11,29.71-19,43.94-3.57,7.42-2.64,12.5,2.46,18.81,11.85,14.67,23.13,29.84,34,45.26,4,5.67,8.25,6.74,14.56,5.84,15.77-2.26,31.62-4,48.74-6.08,3.59,29.26,7.07,57.63,10.69,87.17-15.94,2-31.36,4.27-46.86,5.66-7.8.7-12.51,3.23-15.53,11.07-7,18.19-14.85,36-22.81,53.82-2.28,5.1-1.66,8.62,1.67,12.9,10.21,13.12,20,26.55,30.58,40.71L846.8,964.38c-10.21-13.55-20.28-26.33-29.63-39.62-3.82-5.43-7.75-7.23-14.3-6.3-19.94,2.81-40,4.73-59.93,7.74-3.48.53-7.82,4-9.36,7.24C726.37,948.65,720,964.25,713,980.52Zm42.29-295.23A84,84,0,0,0,671,769.35c0,46.38,37.82,84,84.38,84a84.05,84.05,0,1,0-.15-168.1Z"/>
                <path d="M850.41,441,763.24,451.2c-2-16.54-4.51-32.84-5.81-49.24-.54-6.87-3.25-10-9.31-12.43-18.63-7.59-36.91-16.07-55.6-23.52-3.25-1.3-8.73-.58-11.65,1.44C667,377,653.58,387.39,639.42,397.91l-52.56-70.46c13.62-10.21,26.67-20.33,40.13-29.87,5.1-3.61,6.67-7.17,5.78-13.51-2.72-19.31-4.77-38.72-6.63-58.13-.66-6.86-3.4-10.56-10-13.12-14.86-5.73-29.33-12.5-45-19.3l34.76-80.75c15,6.4,29.52,12,43.46,18.85,7.79,3.83,13.13,2.75,19.71-2.52,14.47-11.6,29.47-22.56,44.63-33.25,5.81-4.09,7.18-8.27,6.06-15.23-2.5-15.43-3.79-31.05-5.76-48.26L801.11,22c2,16.11,4.44,31.77,5.6,47.51.57,7.76,3.25,11.87,10.54,14.75,17.52,6.92,34.91,14.29,51.92,22.36,6.93,3.29,11.5,2.14,17.11-2.29,12.49-9.88,25.47-19.14,38.75-29l52.58,70.48c-13.79,10.3-26.9,20.41-40.39,30-4.75,3.38-6.44,6.8-5.64,12.7,2.8,20.59,4.77,41.29,7.72,61.86.45,3.19,3.7,7.21,6.71,8.62,15.29,7.17,30.93,13.6,47.28,20.65l-34.77,80.78c-14.45-6.2-28.62-11.69-42.23-18.34-8.17-4-14.08-3.66-21.37,2.34-14.56,12-30,22.91-45.28,34-4.37,3.16-6,6.16-5.18,11.86C846.87,406.65,848.39,423.19,850.41,441Zm-68.5-120.18c46,.23,84.17-37.18,84.54-82.93a84.69,84.69,0,0,0-84-85.58c-45.54-.38-84.16,37.56-84.6,83.12A84.44,84.44,0,0,0,781.91,320.79Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconSystem;
