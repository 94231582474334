import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconRange: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M500,80a121,121,0,1,1-85.56,35.44A120.22,120.22,0,0,1,500,80m0-80C389,0,299,90,299,201s90,201,201,201,201-90,201-201S611,0,500,0Z"/>
                <path d="M500,678a121,121,0,1,1-85.56,35.44A120.22,120.22,0,0,1,500,678m0-80c-111,0-201,90-201,201s90,201,201,201,201-90,201-201S611,598,500,598Z"/>
                <g>
                    <path d="M312.94,725.3q3.6-9.13,8.05-17.81,2.22-4.32,4.64-8.54a201.23,201.23,0,0,1,19.27-27.8q6.15-7.46,13-14.28,1.71-1.71,3.45-3.37,3.49-3.33,7.14-6.49t7.45-6.14q1.53-1.2,3.09-2.37v-277q-1.56-1.17-3.09-2.37c-1.27-1-2.52-2-3.76-3a201.64,201.64,0,0,1-27.25-27.25c-1-1.24-2-2.49-3-3.76q-6-7.59-11.2-15.75-2.62-4.08-5-8.29-4.85-8.42-8.87-17.33-2-4.46-3.82-9a199.93,199.93,0,0,1-12.33-48.1A204,204,0,0,1,299,201V799a200.71,200.71,0,0,1,9-59.77Q310.23,732.15,312.94,725.3Z"/>
                    <path d="M699.39,226.6a199.93,199.93,0,0,1-12.33,48.1q-1.8,4.56-3.82,9-4,8.89-8.87,17.33-2.42,4.21-5,8.29-5.22,8.15-11.2,15.75c-1,1.27-2,2.52-3,3.76a201.64,201.64,0,0,1-27.25,27.25c-1.24,1-2.49,2-3.76,3q-1.53,1.2-3.09,2.37v277q1.56,1.17,3.09,2.37,3.81,3,7.45,6.14t7.14,6.49q1.74,1.66,3.45,3.37,6.82,6.82,13,14.28A201.23,201.23,0,0,1,674.37,699q2.42,4.21,4.64,8.54,4.44,8.67,8,17.81,2.7,6.84,4.9,13.93A200.71,200.71,0,0,1,701,799V201A204,204,0,0,1,699.39,226.6Z"/>
                </g>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconRange;
