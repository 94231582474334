// #region imports
    // #region internal
    import Notification from './Notification';
    import Notifications from './Notifications';
    // #endregion internal
// #endregion imports



// #region module
const notifications = {
    Notification,
    Notifications,
};
// #endregion module



// #region exports
export default notifications;
// #endregion exports
