import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconViewable: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M500,249C234.07,249,18.5,500,18.5,500s97,112.94,241.1,187.3h0C330.35,723.79,412.44,751,500,751c265.92,0,481.5-251,481.5-251S765.92,249,500,249ZM785.51,385.32Q761.9,370,738.18,356.9,761.88,369.93,785.51,385.32Zm-47.62-28.61c-2.53-1.39-5-2.76-7.58-4.11C732.84,354,735.36,355.32,737.89,356.71ZM273,350.82q-11.27,5.94-22.52,12.4,11.25-6.45,22.52-12.4ZM73.54,500a905.81,905.81,0,0,1,110.92-94.18q15.9-11.4,31.87-21.71-3,1.95-6,3.94a308.81,308.81,0,0,0,6.25,228q-16.08-10.41-32.08-21.85A905.61,905.61,0,0,1,73.54,500ZM249.3,363.93l-6.11,3.57C245.23,366.29,247.26,365.1,249.3,363.93Zm-8.38,4.91-5.74,3.45Zm-8.35,5.05q-2.82,1.71-5.62,3.48c1.87-1.17,3.75-2.33,5.62-3.48ZM224.3,379l-5.66,3.6Zm-7.75,237q4.26,2.75,8.53,5.44-4.28-2.61-8.53-5.37Zm8.69,5.54q4.2,2.64,8.4,5.21-4.2-2.5-8.4-5.15Zm8.72,5.41c2.75,1.67,5.5,3.32,8.25,5-2.75-1.6-5.5-3.26-8.25-5Zm8.76,5.25q4,2.4,8.09,4.72-4-2.25-8.09-4.66Zm8.75,5.1q4,2.28,8,4.5-4-2.16-8-4.44Zm8.72,4.91q4,2.2,7.93,4.33-3.94-2.07-7.94-4.27Zm8.65,4.72q4,2.13,8,4.21-4-2-8-4.14ZM817.09,593c-47.5,34.22-95.68,61.48-143.21,81.06l-4,1.62C611.81,699.11,554.66,711,500,711c-53.63,0-109.63-11.43-166.52-34q-2.88-1.14-5.76-2.32l1,.43q-25.5-10.35-51.34-23.69,4.13,2.13,8.25,4.2a268,268,0,0,1-12.52-304.85q27.9-14.69,55.64-26C387.24,301.05,444.87,289,500,289c53.92,0,110.24,11.54,167.45,34.3l4,1.61-.2-.08a628.65,628.65,0,0,1,58.58,27.54l-7.68-4a267.49,267.49,0,0,1-12.84,309.83A708.14,708.14,0,0,0,779,618.9a308.82,308.82,0,0,0,6.45-233.55q15,9.81,30,20.51a906.48,906.48,0,0,1,111,94.18,902.6,902.6,0,0,1-109.39,93Z" />
                <path d="M329.19,324.58l-.66.26-.78.33Z" />
                <path d="M662,678.82q-6.36,2.46-12.7,4.73Q655.68,681.27,662,678.82Z" />
                <path d="M593.9,526a98.25,98.25,0,0,0-23.6-93.66q-1.63-1.71-3.33-3.33c-1.14-1.09-2.3-2.14-3.48-3.17l-1.8-1.52q-1.82-1.5-3.71-2.91t-3.84-2.74q-2.94-2-6-3.75t-6.3-3.33a98.42,98.42,0,0,0-64.16-7.27l-1.1.25c-2.71.67-5.37,1.42-8,2.26l-1.54.53c-1.69.59-3.37,1.22-5,1.9a97.81,97.81,0,0,0-12.75,6.37c-1.1.65-2.18,1.32-3.25,2A98.14,98.14,0,0,0,415.21,550.5c.61,1,1.24,2,1.88,3,1.75,2.69,3.64,5.28,5.63,7.78q1.77,2.19,3.64,4.28c.15.18.31.35.47.52a98.12,98.12,0,0,0,166.66-38.53C593.61,527.06,593.75,526.54,593.9,526Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconViewable;
