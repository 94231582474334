import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconTypeAliasTyped: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M608.69,639.06q3,1.74,3.38,4.36c.25,1.74-.65,3.21-2.72,4.41a13.23,13.23,0,0,1-6.1,1.56,23.21,23.21,0,0,1-5.57-.26l-6.86-1.48-192.73-41c1.38,2.8,2.36,4.85,2.91,6.13L438.77,699q2.6,6.45-1.88,9a15.26,15.26,0,0,1-7.85,1.86,16,16,0,0,1-8.06-2q-1.67-1-3.85-4.62L371.6,601.08q-3-6.42,1.81-9.24a13.24,13.24,0,0,1,2.63-1.17,10.35,10.35,0,0,1,3-.48l2.85,0a23,23,0,0,1,3.06.27l2.7.42c.58.1,1.49.27,2.74.52a12.46,12.46,0,0,1,2.07.47L601.75,637A25.58,25.58,0,0,1,608.69,639.06Z"/>
                <path d="M679.67,668.84q1.65,1,3.85,4.61l45.32,102.36q3,6.42-1.61,9.12a13.24,13.24,0,0,1-2.63,1.17,10.35,10.35,0,0,1-3,.48c-1.07,0-2,0-2.74.09a17.53,17.53,0,0,1-3.21-.24l-2.6-.36c-.35,0-1.21-.22-2.59-.54l-2.08-.48-209.48-45a23.26,23.26,0,0,1-6.95-2.34q-3.43-2-3.18-4.58c.16-1.75,1.24-3.19,3.24-4.36a16.13,16.13,0,0,1,4.23-1.73,14.46,14.46,0,0,1,5.44-.09c2.17.29,3.56.5,4.15.6l4.92.93,192.17,41.45-6.4-14.46L661.9,677.6c-1.69-4.08-1-7,1.92-8.7a15.91,15.91,0,0,1,7.81-1.95A15.2,15.2,0,0,1,679.67,668.84Z"/>
                <path d="M945.52,448.46q15.59,9,2.18,16.79-.69.4-168.59,70.62-16.2,7-23.26,6.28t-21.32-8.93q-17.73-10.23-4.31-18,3.43-2,53.83-23.64t79.27-36.42q-16.3-15-63-42.36l-77.88-45L228.16,539.71l68.23,39.39q17,9.79,4.25,17.19c-3.66,2.13-10,4.42-19.09,6.89s-16.15,3-21.21,1.68c-2.77-.53-6.33-2.06-10.73-4.6L57.53,489.37q-19.05-11-6.33-18.4,4.8-2.79,17.37-6.48t21.56-2.09a45.16,45.16,0,0,1,12.44,4.8l73.09,42.2L669.92,337.52,611.7,303.91q-63.76-36-80.54-42.32t-37.1-1.72q-33.4,7.77-62.17,15.45t-43,12.17q-14.3,4.49-26.5,8t-18.08,4.08a38.36,38.36,0,0,1-11.74-.61,43.47,43.47,0,0,1-12.79-5.2l-3.81-2.2q-18.39-10.6-6.56-17.47,2.06-1.2,198.49-57.57,23.07-7,38.32,1.4Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconTypeAliasTyped;
