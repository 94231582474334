import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconObliterate: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M213.94,349.28V39.59c3.92-.19,7.12-.48,10.32-.48q180.87,0,361.73-.17c6.93,0,11.6,2.21,16.37,7,59.12,59.4,118.51,118.54,177.52,178a23,23,0,0,1,6,14.45c.5,36.4.27,72.82.27,110.85h11c37.1,0,74.2-.09,111.3,0,42.32.08,68.56,26.53,68.57,69q0,122.73,0,245.46c0,39.81-27,66.84-66.57,66.9-19.79,0-39.58,0-60.41,0V548.48H150.4V729c-26.82,0-52.81,2.6-78-.69-29.1-3.79-49.1-30.65-49.17-60.18q-.35-127.7,0-255.4c.12-35.79,27.89-63,64.19-63.31,36.76-.35,73.54-.09,110.3-.1Zm63.71-.68H722.11V229.18h-127V103.12H277.65ZM149.46,476.3V413.38H87.26V476.3Z"/>
                <g>
                    <path d="M361.31,817.92c0,87-30.59,136.62-89.3,136.62-58.9,0-89.3-49.6-89.3-136.62,0-86.64,30.4-136,89.3-136C330.72,681.88,361.31,731.28,361.31,817.92Zm-136.23,0c0,26.79,1.52,47.5,4.94,63.08l74.1-151.24c-7.41-10.83-17.86-15.39-32.11-15.39C242,714.37,225.08,744,225.08,817.92ZM315,760,241.8,909.31C249,918.43,259.09,922,272,922c30.4,0,46.93-29.26,46.93-104.12C318.94,794,317.61,774.79,315,760Z"/>
                    <path d="M589.3,817.92c0,87-30.59,136.62-89.3,136.62-58.9,0-89.3-49.6-89.3-136.62,0-86.64,30.4-136,89.3-136C558.71,681.88,589.3,731.28,589.3,817.92Zm-136.23,0c0,26.79,1.52,47.5,4.94,63.08l74.1-151.24c-7.41-10.83-17.86-15.39-32.11-15.39C470,714.37,453.07,744,453.07,817.92ZM542.94,760,469.79,909.31C477,918.43,487.08,922,500,922c30.4,0,46.93-29.26,46.93-104.12C546.93,794,545.6,774.79,542.94,760Z"/>
                    <path d="M817.3,817.92c0,87-30.59,136.62-89.3,136.62-58.9,0-89.3-49.6-89.3-136.62,0-86.64,30.4-136,89.3-136C786.71,681.88,817.3,731.28,817.3,817.92Zm-136.23,0c0,26.79,1.52,47.5,4.94,63.08l74.1-151.24c-7.41-10.83-17.86-15.39-32.11-15.39C698,714.37,681.07,744,681.07,817.92ZM770.94,760,697.79,909.31C705,918.43,715.08,922,728,922c30.4,0,46.93-29.26,46.93-104.12C774.93,794,773.6,774.79,770.94,760Z"/>
                </g>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconObliterate;
