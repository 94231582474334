import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconLetterSpacing: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M316.7,677.17l35.89,35.88-65.06,65.06h186.9c-.14,17.5-.28,34.44-.42,51.84-62.35,1.07-124.13.56-186.55.74L352.73,896l-36,36L189.28,804.6Z" />
                <path d="M316.7,677.17l35.89,35.88-65.06,65.06h186.9c-.14,17.5-.28,34.44-.42,51.84-62.35,1.07-124.13.56-186.55.74L352.73,896l-36,36L189.28,804.6Z" />
                <path d="M519.9,831.12V778.94H706l.29-.79-64.7-64.69,35.9-35.9L804.6,804.67,677.47,931.79,641.71,896l64.91-64.91Z" />
                <path d="M519.9,831.12V778.94H706l.29-.79-64.7-64.69,35.9-35.9L804.6,804.67,677.47,931.79,641.71,896l64.91-64.91Z" />
                <path d="M443.85,581.66h69.64L339.82,68H265.58L91.91,581.66h67L208.1,425.43h187ZM224.62,373l24.59-78c18.43-57.4,34.88-113.52,50.76-173.06h3.13C319.56,181.16,336,237.58,354.41,295l24.35,78Z" />
                <path d="M841.21,68,759,345.42c-17.74,60.41-30.37,109.4-49.38,169.58h-3.12c-19-60.2-31.36-109.19-49.38-169.58L574.41,68H504.77L668.82,581.66H745L908.09,68Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconLetterSpacing;
