import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconFunction: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M928.4,382.3q24,13.83,35.11,32.19T951.63,446.2q-13.38,7.79-34.28,8.45t-34.9-7.44q-9.91-5.71-10.07-12.75t9.1-12.42q6.85-4,18.84-7-5.55-4-17.67-11-24.35-13.26-42.27-10.23t-89.79,30Q717.83,437,696.21,445l70.55,40.73q15.87,9.17,3.65,16.28-23.79,13.82-39.41,9.65a86.07,86.07,0,0,1-12.21-6.16l-65.88-38-6.23-3.15Q372.26,576.15,282,606.57q-149.67,51-212.84,14.5-22.61-13.05-33-30.79T48.4,559.36q14.77-8.58,35.48-9.34t34.73,7.33q9.92,5.73,9.89,13.06c0,4.88-3.1,9.13-9.28,12.72q-6.82,4-19.18,6.79,6.24,4.4,14.89,9.39,15.25,8.82,37.88,6.77t74.64-22q52-20,163.83-62.89,72.75-28,122.35-47l66.25-25.44q16.65-6.39,23.82-9L550.38,409q-16.26-9.39-4-16.5,6.27-3.64,19.2-7.52t20.27-2.07c2.79.54,6.53,2.15,11.19,4.84l56.61,32.69,77.27-29.68q19.71-7.56,41.91-14.66A321.56,321.56,0,0,1,811.18,366a256.73,256.73,0,0,1,60.47-.93Q903.86,368.14,928.4,382.3Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconFunction;
