import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconWordSpacing: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M320.1,677.68l35.81,35.81L291,778.42H477.52c-.14,17.47-.28,34.36-.42,51.74-62.23,1.07-123.88.56-186.18.74L356.06,896l-36,36L192.94,804.85Z" />
                <path d="M320.1,677.68l35.81,35.81L291,778.42H477.52c-.14,17.47-.28,34.36-.42,51.74-62.23,1.07-123.88.56-186.18.74L356.06,896l-36,36L192.94,804.85Z" />
                <path d="M522.89,831.32V779.25H708.62l.29-.79L644.34,713.9l35.82-35.83,126.9,126.85L680.15,931.79l-35.69-35.7,64.78-64.78Z" />
                <path d="M522.89,831.32V779.25H708.62l.29-.79L644.34,713.9l35.82-35.83,126.9,126.85L680.15,931.79l-35.69-35.7,64.78-64.78Z" />
                <path d="M211,68h67.76l53.65,278.79c9.89,55.08,20.06,110.15,29.95,165.23h3.12c11.35-55.08,23.66-110.45,36-165.23L472.45,68h59.48l71,278.79C615.25,401,627.56,456.66,639.88,512H643c9.88-55.36,19.09-110.74,29-165.23L725.62,68h63.29L683,580.63H604L526.64,273.06c-8.79-40-17-77.31-24.64-116.77h-3.12C491,195.75,482.05,233,473.26,273.06L397.47,580.63H319.66Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconWordSpacing;
