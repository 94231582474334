import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconObjectLiteral: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M789.33,341.38q10.24,5.91,3.09,9.76-2.2,1.28-13.64,5.35t-15.57,4.56q-5,.33-11.21-3t-19.76-10.18q-13.56-6.9-28.93-13.36c-6-2.27-11.76-4.2-17.12-5.79s-10.47-2.85-15.27-3.81-9.15-1.74-13-2.37a48.18,48.18,0,0,0-11.61-.48c-3.85.31-6.93.5-9.26.57a43.5,43.5,0,0,0-8.82,1.43c-3.55.89-5.83,1.5-6.84,1.85s-3.08,1-6.21,2.12l-.12-.15c-115,36.43-169.91,59.39-178.46,61.25q-61.56,15-139.92-6.93,16.86,19,12.31,36T284.81,446.7q-9.66,5.63-68.86,26.42-71,24.81-110.63,38.54-11.4,3.93-15.81,6.49-27.51,16,42.32,58.22,9.71,5.61,2.83,9.61-2.76,1.59-18.46,6.88-9.09,3.36-13.37,3.13T90.3,591l-9.66-5.57q-50.2-29-53.41-51.41t19.38-35.54q10.19-5.91,100.53-37.4T246,424.62q21.32-12.39,13.54-30.17t-28.68-32.62q-.56-.31-3.75-2.47t-4.3-2.8a7.79,7.79,0,0,1-2.25-2.22c-.76-1.06-.88-1.95-.36-2.68a9.19,9.19,0,0,1,2.91-2.34q3.19-1.85,14.75-5.68t16.46-3.47a20.87,20.87,0,0,1,6.46,2.18l11.61,5.78A154.3,154.3,0,0,0,291.84,356a253.82,253.82,0,0,0,59.86,14.48q31.8,3.84,59-2Q420.94,365,507,335.32T608,302q37.14-9.15,81.93-.66a263.43,263.43,0,0,1,83.3,30.73Z"/>
                <path d="M919.51,415.9q50.43,29.12,53.33,51.68t-19.68,35.67q-10.73,6.24-98.25,36.07T758,574.59q-22,12.79-15.42,30.31t27.94,32.72c.37.21,1.61,1,3.74,2.48s3.57,2.37,4.3,2.79a8.52,8.52,0,0,1,2.36,2.33c.84,1.12,1,2,.43,2.8a9.9,9.9,0,0,1-3,2.4q-3.3,1.92-15.3,5.83c-8,2.61-13.66,3.81-17,3.59a19.68,19.68,0,0,1-6.24-2q-3.48-1.68-11.1-5.45a152.77,152.77,0,0,0-19.28-7.62,257.94,257.94,0,0,0-60.81-14.38q-32.53-3.79-59.85,2.11l-98.94,33.32L391,699.14q-39.42,7.88-86-1.6T219.9,665.81L204.66,657q-10.8-6.24-3.92-10.24,2.2-1.29,13.64-5.36T230,636.85q5-.32,12.18,3.52t20.5,10.88a255.86,255.86,0,0,0,26.86,12.32q9.13,3.36,17.43,5.76a153.91,153.91,0,0,0,15.5,3.68q7.18,1.28,13.12,2.16a50.37,50.37,0,0,0,12.3.24c4.23-.43,7.55-.7,9.94-.8a33.49,33.49,0,0,0,8.81-1.92l6.74-2.27,6.24-2.1,5.06-1.7q164.12-56.55,174.93-58.91,62.36-13.77,144.83,10.57Q690.2,602.13,691.49,585t25-30.88q6.87-4,28.65-12.16Q905,486.62,910.23,483.58q15.15-8.81,3.74-22.88t-31.5-26.64q-20.1-12.56-20.92-14.33c-.36-1.5.64-2.93,3-4.29s8.71-3.67,19.15-6.95q10.32-3.44,14.5-3t11.66,4.78Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconObjectLiteral;
