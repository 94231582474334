import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconCopyStateHistory: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M565.17,19q-30,8.49-48.06,24.74a91.05,91.05,0,0,0-25.79,39.93q-7.79,23.68-7.78,54.07V244.46q0,29.68-14.13,48.06T423.47,310.9V311q-26.75-5.68-63.21-5.69H346.13v84.1H363.8q31.8,0,42.76,14.13t10.95,43.82v94q0,36.75,9.9,62.19T467,647.4q-29.69,18.39-39.57,43.82t-9.9,62.19v94q0,29.69-10.95,43.82T363.8,905.37H346.13v84.1h14.13q44.53,0,74.57-8.48t48.06-24.74a91.14,91.14,0,0,0,25.79-39.93q7.77-23.69,7.78-54.07V755.53q0-29.67,14.13-48t45.94-18.38V689q26.75,5.68,63.21,5.69h14.13v-84.1H636.2q-31.8,0-42.76-14.13t-11-43.82v-94q0-36.74-9.9-62.19T533,352.6q29.68-18.37,39.57-43.82t9.9-62.19v-94q0-29.68,11-43.82T636.2,94.63h17.67V10.53H639.74Q595.2,10.53,565.17,19Z"/>
                <path d="M916.06,292.52q-14.14-18.36-14.13-48.06V137.75q0-30.39-7.78-54.07a91.14,91.14,0,0,0-25.79-39.93Q850.33,27.51,820.3,19t-74.56-8.48H731.6v84.1h17.67q31.8,0,42.76,14.14t11,43.82v94q0,36.75,9.9,62.19t39.57,43.82Q822.76,371,812.88,396.42T803,458.61v94q0,29.68-11,43.82t-42.76,14.13H731.6v84.1h14.14q44.52,0,74.56-8.48t48.06-24.73a91.17,91.17,0,0,0,25.79-39.94q7.77-23.68,7.78-54.06V460.73q0-29.69,14.13-48.06T962,394.3V310.9Q930.2,310.9,916.06,292.52Z"/>
                <path d="M179.7,313.82q-30,8.48-48.06,24.73a91.11,91.11,0,0,0-25.79,39.93q-7.78,23.7-7.78,54.07V539.27q0,29.68-14.13,48.06T38,605.7v83.4q31.8,0,45.94,18.38t14.13,48V862.25q0,30.39,7.78,54.07a91.05,91.05,0,0,0,25.79,39.93q18,16.25,48.06,24.74t74.56,8.48H268.4v-84.1H250.73q-31.8,0-42.76-14.14T197,847.41v-94q0-36.74-9.9-62.19T147.55,647.4q29.67-18.36,39.57-43.82t9.9-62.19v-94q0-29.68,10.95-43.82t42.76-14.13H268.4v-84.1H254.26Q209.74,305.34,179.7,313.82Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconCopyStateHistory;
