import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconRange: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M570.42,367.27C551.87,217,501.06,115.35,419.41,65.16,379,40.34,331.48,28.43,278.06,29.78c-40.24,1-84.55,9.84-128.13,25.54a42.44,42.44,0,0,0-24.49,23,42.16,42.16,0,0,0,54.23,55.92c69.42-27.55,129.39-27.18,178.22,1.1,36.26,21,66.33,57,89.38,106.88,23,49.73,31.66,98.19,34.33,116.72l1,7.29,7.28,1.16c34.51,5.5,42.63,6.77,46.53,7.37s4,.63,22.44,3.62l13.16,2.13Z"/>
                <path d="M842.59,924l-.52-1.17A43.25,43.25,0,0,0,800,897.54c-29.35,1.9-74,1.89-120.09-8.92-48.85-11.46-86.87-32.26-113-61.83-39.69-44.91-51.73-110.29-35.78-194.33l1.94-10.24-84.82-12.43-1.53,9.79c-5.09,32.67-9.42,82.89-1.07,136.61,9.19,59.13,31.48,108,66.27,145.37C564.77,958.25,645,986.84,751,986.83q26.91,0,56-2.46a42.41,42.41,0,0,0,33.32-21A43.05,43.05,0,0,0,842.59,924Z"/>
                <path d="M928.3,589.64,804.67,571.22l8.84-59.35,123.64,18.42ZM705.76,556.48,557.4,534.38,566.24,475l148.36,22.1ZM458.49,519.65l-148.36-22.1L319,438.2l148.36,22.1ZM211.22,482.81,62.85,460.71l8.85-59.35,148.36,22.11Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconRange;
