import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconSpace: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M475.1,990c-25.56-12.8-51-25.79-76.71-38.36q-169-82.8-338.26-165.27c-5.89-2.87-8.14-6.09-8.13-12.86q.33-256,.18-512v-9.77c4.22,1.93,7.34,3.27,10.39,4.76q203,99.22,406.14,198.3c6.39,3.11,8.48,6.56,8.47,13.64Q476.85,729.2,477,990Z"/>
                <path d="M523,990q0-260.28-.19-520.58c0-7.81,2.43-11.66,9.35-15Q734.85,355.65,937.4,256.5c3.06-1.5,6.19-2.84,10.39-4.77v10.74q0,254.55.21,509.1c0,8.24-2.5,12.1-9.85,15.67Q735.8,885.67,533.74,984.71c-3.08,1.5-5.92,3.5-8.88,5.26Z"/>
                <path d="M501.9,10c8.68,4.55,17.25,9.31,26.05,13.61q190.11,93,380.25,185.92c2.76,1.35,5.43,2.91,9.44,5.08-4.38,2.28-7.63,4.07-10.95,5.69Q708,317.46,509.3,414.69c-6.71,3.29-11.81,3.35-18.55.05q-199-97.64-398.25-194.8c-3-1.48-5.94-3.25-9.94-5.46,4.09-2.16,7-3.82,10.09-5.3Q290.92,112.24,489.2,15.3c3.08-1.51,5.92-3.51,8.87-5.27Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconSpace;
