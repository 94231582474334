import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconAddAlbum: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M812.94,10c28.1,7.56,42.47,27.37,48.5,54.53.64,2.88.6,5.91.95,9.55h-5.26c-19.49,0-39,.09-58.47-.12a12.57,12.57,0,0,1-7-2.59A24.14,24.14,0,0,0,776.42,66q-278.61.06-557.23,0a22.65,22.65,0,0,0-15.93,6A7.27,7.27,0,0,1,199,74c-21.47.12-42.94.08-65,.08-.61-8.38,1.44-16,4.44-23.34,8.4-20.51,22.28-35.21,44.49-40.73Z"/>
                <path d="M69,179.7a77.65,77.65,0,0,1,16.11-45.46C99,116,117,106.78,140.47,106.8q358.18.39,716.35.16c32.76,0,57.35,17.29,68,48.4,2.6,7.55,3.79,15.59,5.78,24.07-3.13.14-4.75.27-6.37.27-20.66,0-41.32,0-62,.07a7.86,7.86,0,0,1-7.11-3.37,31.23,31.23,0,0,0-25-12.52c-1.83,0-3.66,0-5.49,0q-325.44,0-650.87-.11c-13.17,0-24.27,3.17-32.3,14.22a4.67,4.67,0,0,1-3.29,1.68C115.4,179.72,92.6,179.7,69,179.7Z"/>
                <path d="M734.16,557.16H542.84V365.84A42.84,42.84,0,0,0,500,323h0a42.84,42.84,0,0,0-42.84,42.84V557.16H265.84A42.84,42.84,0,0,0,223,600h0a42.84,42.84,0,0,0,42.84,42.84H457.16V834.16A42.84,42.84,0,0,0,500,877h0a42.84,42.84,0,0,0,42.84-42.84V642.84H734.16A42.84,42.84,0,0,0,777,600h0A42.84,42.84,0,0,0,734.16,557.16Z"/>
                <path d="M977.53,260.35a66.19,66.19,0,0,0-63.41-49.43l-4.5,0q-409.74,0-819.47-.09c-24.48,0-44.05,9-58.31,28.87-8.31,11.6-11.78,24.83-11.78,39q0,81.5,0,163,0,240,0,480c0,33,20.48,59.45,51.71,66.62A74,74,0,0,0,88.13,990q411.74.12,823.47,0c35.52,0,61.77-22.63,67.52-57.72a13.76,13.76,0,0,1,.82-2.33V271C979.14,267.42,978.46,263.85,977.53,260.35ZM922.24,896.49c0,17.3-9.58,30.08-25.31,33.84a45.66,45.66,0,0,1-10.4.92c-17.65.09-35.31,0-53,.11a12.4,12.4,0,0,1-3.2-.36h-86.3l.31.29H113.88c-18.44,0-31.23-8.92-35.27-24.8A43.08,43.08,0,0,1,77.5,896.1q0-16.05,0-32.1H77V326h.46V305.55C77.46,282.76,91,269.3,114,269.3H885.83c18.66,0,31.78,9.53,35.5,25.91a43,43,0,0,1,.79,9.43q.06,193.73,0,387.44V698l-.16-.15v83.3a24.56,24.56,0,0,1,.32,4.42C922,822.54,922.28,859.51,922.24,896.49Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconAddAlbum;
