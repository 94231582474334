import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconAnnotation: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M303.88,639.19V629.7q0-248.06.13-496.1c0-6.44-1.32-8.6-8.2-8.44-31.29.72-62.68-1.13-93.82,3.26-44.05,6.22-75.25,28-87.56,72.71-5.23,19-7.7,38.49-10.16,58-1,7.46-2.07,14.9-2.8,22.38-.35,3.64-1.75,5-5.46,4.95-9.71-.19-19.43-.25-29.14,0-4.59.12-5.3-1.91-4.67-5.75,4.71-28.77,5.84-57.89,8.55-86.85q4.73-50.38,9-100.8c.46-5.43,2.57-7,8.07-7,169.73.17,339.47,0,509.2.38,13.24,0,26.61.87,39.9,1.72,4.92.31,7.16,2.11,7.57,7.36,3.41,43.07,7.1,86.11,10.64,129.16,1.49,18.1,2.65,36.23,4.32,54.32.59,6.34-1.81,7.82-7.7,7.46a181.78,181.78,0,0,0-24,0c-6.34.44-7.46-2-7.53-7.84-.34-28.08-5.21-55.6-13.36-82.36-12.7-41.74-43.13-62.38-85-67.79-20.72-2.68-41.68-2.28-62.54-3-10.92-.36-21.85-.55-32.75-1.19-4.62-.28-6.58.77-6.26,5.83.43,6.77.11,13.59.11,20.39q0,182.48,0,365c0,4.5-.47,8.3-4,11.81Q362,581.54,307.7,636C306.88,636.79,305.94,637.47,303.88,639.19Z" />
                <path d="M382.24,738.72c0-13.19,5.69-23.77,14.8-32.87Q527.62,575.32,658.17,444.77c22-22,44.11-43.79,65.76-66.08,4.87-5,7.44-4.29,11.82.2q24.38,25,49.42,49.42c4.41,4.29,4.88,6.55.14,11.25q-89.65,89.06-178.85,178.56-74.18,74.16-148.33,148.32c-21.37,21.33-52,19.85-68.13-3.16A45.51,45.51,0,0,1,382.24,738.72Z" />
                <path d="M792.35,225.79c23.16.78,42.74,10.25,59,26,21.94,21.26,43.21,43.23,64.52,65.13,7.67,7.88,12.55,17.56,16.41,27.93C945,379,935.62,408,912.38,433.83c-8.43,9.37-17.83,17.85-26.59,26.93-2.81,2.91-4.67,2.95-7.58,0q-67-67.28-134.17-134.36c-13.38-13.37-26.82-26.68-40.11-40.14-1.7-1.72-5.77-3.22-2.08-6.81,12.5-12.17,23.67-25.8,37.71-36.26C755.07,231.67,772.67,225.55,792.35,225.79Z" />
                <path d="M346.89,683.76c-7.09-.37-13.84-4.52-17.52-12.78-3.85-8.67-2-16.79,4.44-23.45,11.1-11.53,22.57-22.7,33.88-34Q493.18,488,618.69,362.45c14.74-14.75,29.73-29.26,44.14-44.32,4.95-5.17,8-4.75,12.43.26A260.9,260.9,0,0,0,694.77,338c4.18,3.78,3.48,6-.24,9.65q-74.29,73.89-148.31,148.08-90.06,90-180.11,180C361.28,680.56,355.88,683.83,346.89,683.76Z" />
                <path d="M480,814.67c-.15-7.16,3.45-12.43,8.24-17.21q69-69,138-138Q720.39,565.28,814.55,471c6.62-6.63,6.6-6.61,13.18,0,6.32,6.38,12.48,12.94,19.12,19,4.09,3.71,3.71,6-.09,9.77q-53.21,52.8-106.08,105.93Q636.45,710,532.2,814.16c-5.67,5.66-11.2,11.48-17.2,16.76-6.84,6-14.88,7-23,3C484.16,830.08,480.2,823.43,480,814.67Z" />
                <path d="M457.35,862.72c-19.66,5-38.55,9.88-57.48,14.62q-70.29,17.6-140.62,35.09c-10.46,2.61-10.52,2.58-8-7.43q22.14-88.54,44.33-177.07c1.76-7,3.49-14.07,5.41-21.81,10.11,10,21.33,16.76,34.66,19.33,4.06.78,4.33,2.78,3.94,6.09-6.2,51.49,41.19,99.29,93.32,92.59,2.54-.32,5.7-1.12,6.25,2.9C441,840.71,447.54,852.08,457.35,862.72Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconAnnotation;
