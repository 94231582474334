import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconBrainCircuits: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M116.1,670.5c23.2-23.2,26.1-58.9,8.9-85.2l44.9-44.9l129.4,7.8L423,424.5l1.4-1.4l123.7-123.7l-7.8-129.4
                    l44.9-44.9c26.4,17.2,62.1,14.3,85.2-8.9c26.6-26.6,26.6-69.6,0-96.2s-69.6-26.6-96.2,0C550.8,43.4,548,79.5,566,106l-53.2,53.2
                    l4.9,129.4L403.2,401.9l0,0l-0.7,0.7l-0.7,0.7l0,0L288.6,517.8l-129.4-4.9L106,566C79.6,548.1,43.3,551,20,574.3
                    c-26.6,26.6-26.6,69.6,0,96.2C46.5,697,89.5,697,116.1,670.5z"/>
                <path d="M127.4,461.3c10.6-10.6,16.9-23.8,19.1-37.5h103.3l86.3-86.3l1.4-1.4l86.3-86.3V146.6
                    c13.7-2.2,26.9-8.5,37.5-19.1c26.6-26.6,26.6-69.6,0-96.2s-69.6-26.6-96.2,0s-26.6,69.6,0,96.2c8.6,8.6,18.9,14.4,29.8,17.4
                    l0.6,90.8l-67.9,67.9l-67.7-67.7c14.7-25.6,10.8-59.2-11.5-81.5c-26.6-26.6-69.1-27-95.1-1.1s-25.5,68.6,1.1,95.1
                    c22.3,22.3,55.9,26.2,81.5,11.5l67.7,67.7l-67.9,67.9l-90.8-0.6c-3-10.9-8.8-21.2-17.4-29.8c-26.6-26.6-69.6-26.6-96.2,0
                    s-26.6,69.6,0,96.2S100.8,487.8,127.4,461.3z"/>
                <path d="M871.3,536.1c-10.6,10.6-16.9,23.8-19.1,37.5H751.8L663.5,662l-1.4,1.4l-88.4,88.4v100.4
                    c-13.7,2.2-26.9,8.5-37.5,19.1c-26.6,26.6-26.6,69.6,0,96.2c26.6,26.6,69.6,26.6,96.2,0c26.6-26.6,26.6-69.6,0-96.2
                    c-7.8-7.8-17.1-13.3-26.9-16.5l-2.8-89.5l69.3-69.3l66.3,66.3c-14.7,25.6-10.8,59.2,11.5,81.5c26.6,26.6,69.1,27,95.1,1.1
                    c26-26,25.5-68.6-1.1-95.1c-22.3-22.3-55.9-26.2-81.5-11.5l-66.3-66.3l69.3-69.3l89.5,2.8c3.2,9.8,8.7,19.1,16.5,26.9
                    c26.6,26.6,69.6,26.6,96.2,0c26.6-26.6,26.6-69.6,0-96.2C941,509.6,897.8,509.6,871.3,536.1z"/>
                <path d="M882,326.2c-22.9,22.9-26,58.1-9.4,84.4l-45.7,45.7l-126.6-7.8L575,573.6l-1.4,1.4L448.4,700.2l7.8,126.6
                    l-45.7,45.7C384.2,856,349,859,326.1,882c-26.6,26.6-26.6,69.6,0,96.2c26.6,26.6,69.6,26.6,96.2,0c22.9-22.9,26-58.1,9.4-84.4
                    l53.5-53.5l-6.4-128l116-116l1.4-1.4l116-116l128,6.4l53.5-53.5c26.3,16.6,61.5,13.5,84.4-9.4c26.6-26.6,26.6-69.6,0-96.2
                    C951.5,299.6,908.5,299.6,882,326.2z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconBrainCircuits;
