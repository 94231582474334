// #region module
export const PLURID_DEFAULT_CONFIGURATION_SPACE_CULLING_DISTANCE = 1_500;


export const PLURID_DEFAULT_CONFIGURATION_LINK_SUFFIX = "'";

export const PLURID_DEFAULT_CONFIGURATION_LINK_PREVIEW_SHOW = true;
export const PLURID_DEFAULT_CONFIGURATION_LINK_PREVIEW_FADE_IN = 800;
export const PLURID_DEFAULT_CONFIGURATION_LINK_PREVIEW_FADE_OUT = 400;
export const PLURID_DEFAULT_CONFIGURATION_LINK_PREVIEW_OFFSET_X = 5;
export const PLURID_DEFAULT_CONFIGURATION_LINK_PREVIEW_OFFSET_Y = 0;


export const PLURID_ROUTE_SEPARATOR = '://';

export const PLURID_ROUTE_DEFAULT = 'default';
export const PLURID_ROUTE_DEFAULT_PATH_VALUE = '/';
export const PLURID_ROUTE_DEFAULT_PATH = 'p';
export const PLURID_ROUTE_DEFAULT_SPACE_VALUE = 'default';
export const PLURID_ROUTE_DEFAULT_SPACE = 's';
export const PLURID_ROUTE_DEFAULT_UNIVERSE_VALUE = 'default';
export const PLURID_ROUTE_DEFAULT_UNIVERSE = 'u';
export const PLURID_ROUTE_DEFAULT_CLUSTER_VALUE = 'default';
export const PLURID_ROUTE_DEFAULT_CLUSTER = 'c';


export const PLURID_DEFAULT_CONFIGURATION_NETWORK_HOST = 'originhost';


export const PLURID_ROUTER_STORAGE = '__PLURID_ROUTER__';
// #endregion module
