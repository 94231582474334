import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconClassPrivateProperty: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <polygon points="668.07 130.41 566.94 628.57 510.86 660.95 128.69 881.95 72.7 913.92 173.84 415.76 244.8 374.79 162.37 780.83 514.67 577.43 597.11 171.38 668.07 130.41"/>
                <polygon points="317.63 141.01 633.87 64.65 597.13 164.69 322.04 230.81 249.33 349.27 206.7 321.73 317.63 141.01"/>
                <path d="M926.77,556.59q.38,93.6.53,187.33c.05,26.93-13.33,55-34.88,73.35a87.08,87.08,0,0,1-12.17,8.56c-30,17.39-59.58,34.47-89.12,51.53s-59.08,34.09-88.63,51.1c-23.31,13.42-41.52,6.86-46-16.37a60.34,60.34,0,0,1-.73-11q-.33-94.17-.59-188.39c0-8.9,2.39-13.06,10.19-17.58l29.2-16.86c.24-5.54.4-11,.65-16.43,2.44-52.73,41-119,85.37-149.79q4.77-3.3,9.49-6c46.48-26.83,89-12.33,96,40a164.88,164.88,0,0,1,1.22,21l28.38-16.38C924.66,545.46,926.74,546.58,926.77,556.59ZM738.44,653,849,589.13c-.27-8.41-.64-16.78-2.5-23.81-6.5-24.65-30.35-30.68-55-16.42q-2.75,1.58-5.49,3.5c-29,20.24-51.5,58.54-52.92,91.88-.16,3.68-.26,7.68-.33,11.91l5.74-3.24m67.95,79.16c9.93-15.15,10.87-32.5,2.52-39-4.58-3.54-11.16-2.92-17.78.9a46.41,46.41,0,0,0-15.33,15.43c-9.61,15.2-11.18,33.6-1.92,38.51,5.29,2.79,6.37,8,5.9,15.65-.22,3.74,0,7.27,0,10.9,0,6.17,0,12.37.05,18.5.1,8.87,3.49,10.57,11.55,5.91s11.56-10.28,11.58-19.16c0-12.19.07-24.39-.15-36.43-.08-3.88.79-7,3.58-11.24"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconClassPrivateProperty;
