import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconToolbox: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M954.73,471.86c.07,1.56.15,2.53.15,3.49q0,172.95,0,345.91c0,10.09-4.35,18.44-9.94,26.37a52.23,52.23,0,0,1-21.38,17.53c-5.42,2.45-11.49,3.66-17.38,4.85-3.51.71-7.26.38-10.9.38q-395.67,0-791.32.07c-12.31,0-23.83-2-34.25-9-12.46-8.28-20.4-19.76-24.35-34.08a17.05,17.05,0,0,1-.22-4.36Q45.07,649,45,475c0-.84.09-1.69.17-2.92,1.34-.07,2.63-.18,3.92-.18H323.59c4.94,0,5,0,5,5.16,0,13,.07,26,0,39-.07,10.6.37,21.14,3.73,31.29a54.57,54.57,0,0,0,16.56,24.35c12.67,11,27.82,14.31,44,14.32q108.26.12,216.51,0c11.27,0,22.34-2,32.23-7.65,16.24-9.27,26.85-23.17,28.4-42,1.49-18.11,1.09-36.38,1.38-54.59.15-9.89,0-9.89,9.76-9.89H954.73Z"/>
                <path d="M954.72,413.82H45.2c-.06-1.14-.16-2.08-.16-3,0-39.37.1-78.73,0-118.09,0-9.5,3.78-17.6,9-24.88a62.58,62.58,0,0,1,14.27-14.28,52,52,0,0,1,26.62-9.87c3.27-.23,6.56-.25,9.84-.25q109.53,0,219.05,0c5.35,0,5.34,0,5.36-5.36.07-18.34-.21-36.7.33-55,.61-21,11.25-36.08,29-46.61,9-5.33,18.8-6.85,29-6.85q112.26,0,224.52,0c24.75,0,42.41,11,53.69,32.68,4.54,8.73,5.09,18.36,5.08,28q0,23.7,0,47.39c0,5.79,0,5.79,5.66,5.79q109.52,0,219.05,0c8.72,0,17.21.81,25.29,4.29A57.35,57.35,0,0,1,949.13,273c4.39,7.89,5.89,16.14,5.84,25.11-.22,37.42-.09,74.84-.09,112.26C954.88,411.42,954.78,412.49,954.72,413.82ZM499.93,243.38q55,0,110,.06c3.13,0,4.24-.81,4.21-4.08-.15-16.39-.13-32.79,0-49.19,0-2.92-.79-4-3.9-4q-110,.12-220.08,0c-3.32,0-4.42.9-4.39,4.3.14,16,0,32.06,0,48.09,0,4.76,0,4.78,4.82,4.78Z"/>
                <path d="M500.32,471.87c36.31,0,72.62,0,108.93-.08,3.47,0,4.56.9,4.52,4.46q-.27,23.85,0,47.72c0,3.38-1,4.27-4.35,4.27q-109.11-.13-218.22-.07c-5,0-5,0-5-4.94,0-15.79.08-31.57-.08-47.36,0-3.26,1-4.07,4.15-4.06Q445.32,472,500.32,471.87Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconToolbox;
