import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconRange: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M672.56,140C846,158.85,982.77,313.79,986.9,492.13,991.14,675.35,860.09,831.22,671.35,861,802.46,772.63,874.76,654.7,874.9,500.63,875,345.77,802.86,227.28,672.56,140Z"/>
                <path d="M332,409.44V554.73c0,61-1.52,62.71-61.05,68.25-1.38.12-2.65,1.47-5.2,3v28.68H474.25V623.29c-4.56-.71-8.33-1.16-12-1.91-44.4-9-49.34-14.91-49.25-60.28.11-54.17.23-108.33.75-162.49.17-17.87,1.6-35.72,2.6-56.5-15.81,2.42-31.31.19-39.68,6.88-31.63,25.3-66.9,35.53-106.46,34.59-2-.05-4,1.52-6.83,2.65.43,7.21.85,14.1,1.39,23.21Z"/>
                <path d="M374,869a367.92,367.92,0,1,1,143.64-29A366.71,366.71,0,0,1,374,869Zm0-658a289,289,0,1,0,204.35,84.65A287.06,287.06,0,0,0,374,211Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconRange;
