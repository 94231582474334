import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconRepository: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M272.4,923V855.1c-3-.29-5.36-.72-7.76-.73-15.82-.06-31.64.06-47.46,0-34-.23-58.58-24.72-58.89-58.63,0-1.9,0-3.79,0-5.69q0-334.56,0-669.12c0-41,22.89-63.88,64.08-63.88H777.59c41.2,0,64.12,22.82,64.12,63.85q0,335,0,670.07c0,40.36-23.13,63.41-63.61,63.42H461.79v68.57m342-258.75v-9.34q0-267.57,0-535.15c0-18.83-6-24.69-25.21-24.7h-557c-19.59,0-25.3,5.78-25.3,25.55q0,266.63,0,533.26v10.38ZM461.84,815.59a29.78,29.78,0,0,0,4.05.72q159,0,317.91,0c12.58,0,19.71-7.57,19.89-20.4.22-15.5.06-31,.06-46.5v-46.3H196.34c0,32.19-.2,63.77.12,95.35.09,9.35,6.72,17.14,15.67,17.45,19.78.71,39.6.22,60.43.22V740.91H461.84ZM423.39,880V779.15H310.84V880.06" />
                <path d="M385.4,265.43H272.72V152.7H385.4Zm-37.91-38V190.61H310.63V227.4Z" />
                <path d="M385.65,323.31V436H272.8V323.31Zm-38,75v-37H310.84v37Z" />
                <path d="M273,493.84H385.37V606.91H273Zm74.72,38.27h-37v36.8h37Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconRepository;
