import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconTools: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M232.52,45c8.72,1.92,17.56,3.43,26.15,5.81,58.4,16.16,98,52.9,118,110,12.59,36,13.54,72.51.84,109-.77,2.22.25,6.24,1.93,7.95,23,23.48,46.24,46.72,69.45,70q34,34.11,68.11,68.19,37.68,37.67,75.38,75.31Q626.17,525.1,660,558.91c20.7,20.66,41.36,41.36,62.28,61.8,1.71,1.67,5.74,2.64,8,1.85,68.17-24,148.25,2.27,190.84,59.66a168.38,168.38,0,0,1,31.23,72.68c.64,3.75,1.77,7.42,2.68,11.12v32q-7.37,26.44-14.77,52.88a45.19,45.19,0,0,1-2.42,5.42c-7.86-7.71-15.28-14.89-22.58-22.19-30-29.94-59.77-60-89.82-89.84-23.09-22.92-58.06-23.12-81-.77-22.23,21.66-22.09,57.17.8,81,15.6,16.23,31.77,31.91,47.7,47.83q29.28,29.28,58.56,58.54c1.68,1.69,3.23,3.51,6.39,7-13.25,4.27-25,9.4-37.25,11.77-71.63,13.84-129.74-9-173.73-66.8-16.36-21.5-25.45-46.47-29.79-73.24-4.44-27.34-2.59-54,6.41-80.19.77-2.22-.24-6.23-1.92-7.94-25.61-26-51.45-51.86-77.28-77.7Q490.44,589.82,436.51,536L320,419.5c-13.69-13.68-27.48-27.26-41-41.14-2.87-3-5.29-3.23-8.88-2-84.91,29.8-186.89-16.7-216.22-110C40.82,224.74,44,183.81,63.1,143c2.64,2.47,4.87,4.44,7,6.54q52.19,52.08,104.37,104.15c22.94,22.74,54.49,24.76,78.16,5.41,25.69-21,27.34-60.13,3.25-84.39-35.13-35.36-70.5-70.5-105.74-105.75-2-2-3.82-4.21-6-6.61,16.9-8.84,34.44-12.91,52.31-16,1.88-.32,3.71-.9,5.57-1.36Z"/>
                <path d="M45,860.51c1.33-4.86,2.77-9.69,4-14.58,3.66-14.81,11.81-27,22.36-37.61Q209.15,670.26,347.15,532.38a21.46,21.46,0,0,1,2-1.59l120.24,120.5c-20.41,20.3-41.22,40.92-61.93,61.62Q300.57,819.74,193.77,926.64C179.12,941.31,162.05,951,141.37,954a6.39,6.39,0,0,0-1.88,1h-18.9a19.28,19.28,0,0,0-3.29-1.24c-27.25-5.12-48.61-18.91-61.43-43.8C51,900.44,48.55,889.63,45,879.41Z"/>
                <path d="M955,106.05c-7.77,14.43-15.67,28.8-23.28,43.31-13,24.83-25.87,49.76-38.9,74.6-1.06,2-2.62,4.25-4.54,5.26-35.59,18.57-71,37.45-107,55.14-13.38,6.57-22.61,17.18-32.66,27.12-34.22,33.81-68.14,67.92-102.13,102-8.33,8.34-16.5,16.85-24.46,25l-61-61.21c7.31-6.74,15.32-13.66,22.81-21.11q49.88-49.62,99.5-99.48c5.3-5.3,10.33-10.91,16-15.83,14.8-12.94,20.43-31.47,29.36-47.91,14.42-26.56,27.88-53.65,41.95-80.4a14.94,14.94,0,0,1,6.14-5.57q54.93-28.77,110-57.32c2.54-1.32,4.85-3.07,7.27-4.62h2.9c1.2,1.73,2.16,3.69,3.62,5.15q17.52,17.61,35.23,35c6.29,6.15,12.86,12,19.3,18Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconTools;
