import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconQueue: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M500.57,39C246.27,38.65,38.59,246.4,39,500.68,39.48,755.14,245.89,961.1,500.37,961c254.72-.1,460.56-206,460.6-460.73C961,245.79,755,39.36,500.57,39ZM665.4,293.89c3.18,73.55-28.85,129.15-87.32,171-12.73,9.11-21,19.37-20.42,35.77.45,13.31,5.13,23.45,16.4,31.32,40.67,28.4,73.11,64,84.41,113.42,5.72,25,4.93,51.58,6.75,77.46.59,8.39.09,16.86.09,26.7,10.18,0,19.05.23,27.91-.06,11-.35,20.64,1.25,24.35,13.68,3.88,13-3.72,19.81-13.55,25.47H296.07c-15.65-9.6-19.79-23-9.2-32.87,3.88-3.61,10.43-5.34,16-5.95,9.7-1.07,19.58-.59,29.38-.8.34,0,.67-.5,2.54-2,0-12.28.62-25.83-.1-39.32-4-75.75,29.68-132.08,89.8-174.6,11.91-8.43,18-18.65,17.91-33.11-.08-13.81-5.14-24.19-16.87-32.31-40.39-28-72.49-63.32-83.83-112.13-5.9-25.38-5-52.37-6.82-78.65-.58-8.37-.09-16.81-.09-26.47-10.35,0-19.24-.34-28.08.08-11.81.56-21.49-2.1-24.64-15-3-12.27,4.33-19,14-24.13H704c14.29,8.92,19.08,19.94,11.2,29.93-3.65,4.62-10.94,7.92-17,8.74-10.38,1.41-21.08.39-32.91.39C665.31,265.91,664.8,279.92,665.4,293.89Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconQueue;
