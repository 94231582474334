import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconSittings: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M47,518.93H358.67V403.54l-7.13-4.6c-17.8,24.58-37,48.27-53,74-16.87,27.17-41,34.69-70.18,32.5-24.92-1.88-41.86-14.68-45.85-40-3.84-24.43,8.52-39.1,30.82-48.75,10.64-4.6,21-13.33,27.74-22.82,20.79-29.18,40.27-59.33,59.36-89.68,18.37-29.21,44.57-45.23,78.49-45.56q121.82-1.18,243.65.22c30.41.33,54.11,14.7,71,40.67,17.25,26.51,34.21,53.44,54,78,13.14,16.31,33,27.21,46.14,43.51,10.26,12.72,23.37,32.57,19.79,44.69-4.62,15.6-22.76,34.11-38.43,38.08-31.3,7.92-59.77-3.11-78.15-33.76-14.75-24.58-31.93-47.69-48-71.45l-9.11,3.24V517.36H953V991.25H872.4V606.33H640.49c-1.35,2.09-3.26,3.73-3.44,5.54-6.88,68.92-12.23,73.82-81.26,73.84q-55.39,0-110.8,0c-72,0-79.32-6.63-86.25-82.31H300.87c-47.49,0-95,1.26-142.44-.51-24.41-.91-30.18,8-30,31,1.06,105.51.46,211,.46,316.58V990.7H47Z"/>
                <path d="M355,992.33H291.49V708.85H707.07V991.64H647.45V773.49c-21.6-9.64-31.32-5.57-31.06,17.6.51,45.38.39,90.76,0,136.13-.31,39-21.45,65.48-52.29,66.58-32.59,1.16-55.88-24-56.83-65.93-1-43.22,1.3-86.5,1.8-129.77.11-9.57-1.23-19.16-1.9-28.74L496.73,769c-1.18,7.87-3.21,15.73-3.43,23.63-1.32,47.46-1.57,95-3.5,142.41-1.55,38-21.35,59.28-52.06,58.8-31.57-.49-53.29-24.5-53.86-61.59-.72-46.42-.65-92.87,0-139.29.24-18.52-4.22-29.25-28.9-21.8Z"/>
                <path d="M505.44,216.68c-59.63-.31-106.55-47.38-106.24-106.57.3-56.62,48.07-103.5,105.9-103.95,58.28-.44,108.95,48.69,108.49,105.21C613.13,169.51,564.36,217,505.44,216.68Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconSittings;
