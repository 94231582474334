// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #endregion libraries
// #endregion imports



// #region module
export const StyledTextItem = styled.div`
    display: flex;
    align-items: center;
    margin-right: 2rem;
`;
// #endregion module
