import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconFunctionTyped: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M928.4,291.34q24,13.83,35.11,32.19t-11.88,31.72q-13.38,7.77-34.28,8.44t-34.9-7.43q-9.91-5.73-10.07-12.76t9.1-12.41q6.85-4,18.84-7-5.55-4-17.67-11-24.35-13.25-42.27-10.23t-89.79,30q-32.76,13.21-54.38,21.22l70.55,40.73q15.87,9.16,3.65,16.28Q746.62,424.9,731,420.72a86.87,86.87,0,0,1-12.21-6.15l-65.88-38-6.23-3.15Q372.26,485.19,282,515.61q-149.67,51-212.84,14.5-22.61-13.05-33-30.79T48.4,468.4q14.77-8.58,35.48-9.34t34.73,7.33q9.92,5.73,9.89,13.06c0,4.88-3.1,9.13-9.28,12.72q-6.82,4-19.18,6.79,6.24,4.4,14.89,9.4,15.25,8.8,37.88,6.76t74.64-22q52-20,163.83-62.9,72.75-28,122.35-47l66.25-25.43q16.65-6.4,23.82-9L550.38,318q-16.26-9.41-4-16.5,6.27-3.66,19.2-7.53t20.27-2.07c2.79.55,6.53,2.16,11.19,4.84l56.61,32.69q57.55-22.11,77.27-29.67t41.91-14.67A321.56,321.56,0,0,1,811.18,275a256.73,256.73,0,0,1,60.47-.93Q903.86,277.17,928.4,291.34Z"/>
                <path d="M549.69,579.62a6,6,0,0,1,3.38,4.35c.25,1.74-.65,3.21-2.72,4.41a13.23,13.23,0,0,1-6.1,1.56,23.32,23.32,0,0,1-5.57-.25l-6.86-1.49-192.73-41c1.38,2.8,2.36,4.85,2.91,6.13l37.77,86.28q2.6,6.43-1.88,9a15.26,15.26,0,0,1-7.85,1.85,16,16,0,0,1-8.06-2q-1.67-1-3.85-4.62L312.6,541.64q-3-6.42,1.81-9.24a13.24,13.24,0,0,1,2.63-1.17,10.35,10.35,0,0,1,3-.48l2.85,0A21.39,21.39,0,0,1,326,531l2.7.42c.58.09,1.49.27,2.74.51a13.07,13.07,0,0,1,2.07.48l209.29,45.12A25.58,25.58,0,0,1,549.69,579.62Z"/>
                <path d="M620.67,609.39q1.65,1,3.85,4.62l45.32,102.35c2,4.29,1.5,7.32-1.61,9.13a13.24,13.24,0,0,1-2.63,1.17,10.35,10.35,0,0,1-3,.48c-1.07,0-2,.05-2.74.09a16.84,16.84,0,0,1-3.21-.25c-1.39-.19-2.25-.32-2.6-.35s-1.21-.22-2.59-.55l-2.08-.47-209.48-45a23.55,23.55,0,0,1-6.95-2.34q-3.43-2-3.18-4.59t3.24-4.35a16.16,16.16,0,0,1,4.23-1.74,14.67,14.67,0,0,1,5.44-.09c2.17.3,3.56.5,4.15.6l4.92.93,192.17,41.45q-.85-1.9-6.4-14.45L602.9,618.15c-1.69-4.08-1-7,1.92-8.7a15.91,15.91,0,0,1,7.81-2A15.2,15.2,0,0,1,620.67,609.39Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconFunctionTyped;
