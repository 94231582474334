import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconClassProtectedProperty: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <polygon points="690.71 123.29 589.58 621.44 533.5 653.82 151.33 874.83 95.34 906.79 196.48 408.64 267.44 367.67 185.01 773.71 537.32 570.31 619.75 164.26 690.71 123.29"/>
                <polygon points="340.26 133.89 656.5 57.53 619.77 157.56 344.68 223.68 271.97 342.15 229.34 314.61 340.26 133.89"/>
                <path d="M776.15,566.4a16.67,16.67,0,0,1,3.84-1.65c40.15-10.16,80.24-20,120.4-30.17,3.3-.83,4,.43,4.05,4.11,0,29.24.1,58.35.18,87.5-.19,28.65.36,56.89-.29,85.79-.22,9.71-2.7,21-5.57,31.75-20.41,76.73-60.09,139.68-118.85,187.9a23.39,23.39,0,0,1-3.07,2.12c-4.27,2.46-8.94,3.41-13.16,4.46C702.83,953.27,665.81,928.13,650,867.4a40.23,40.23,0,0,1-1.08-9.59q-.33-85.93-.61-171.88c0-3.69,1.07-5.58,4-8.26q59.91-54.07,119.78-108.35A24.28,24.28,0,0,1,776.15,566.4Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconClassProtectedProperty;
