import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconWebhook: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M427.45,452.44C390.54,513.21,354.22,573,320.18,629c6.62,21.13,16,39,17.18,57.32,2.72,41.76-32.67,75.85-74.4,75.63s-75.55-34.65-74.72-76.28,35.58-75.81,77.42-72.73c16.1,1.19,23.61-3.61,31.19-16.8,22.74-39.58,47-78.32,70.55-117.42,3.08-5.12,5.79-10.47,8.57-15.52-100.4-83.84-87.28-200.78-35.22-266.3,58.06-73.08,161.55-93,241.84-45.64,74.69,44,113.24,142.79,82.79,215.39l-33.39-10c12-48.3,5.55-92.73-23.46-132.68-20.82-28.68-48.72-47.49-83-56.22-70.91-18.08-143,16.16-173.44,81.89C317.69,323.9,342.68,392.41,427.45,452.44Z" />
                <path d="M414.44,668.82H620.73c2.31-5.28,4.22-10.21,6.58-14.92,15.84-31.6,49.79-47.54,84.2-39.66,32.88,7.53,57.79,39.42,57.42,73.51-.37,33.66-24.74,64.46-56.95,72-34.51,8.06-69.38-7.17-84-39.21-5.53-12.15-11.91-15.39-24.32-15.25-51.68.62-103.38.26-154.81.26-22,132.09-130.84,179.22-212.64,167-91.51-13.68-160-92.71-160.42-185.1-.39-85,63.6-167.5,142.05-180.69,2.74,11.23,5.51,22.63,8.2,33.68-89.95,39.8-127.67,105-107.83,183.77C136,794.81,201.38,841.52,275.37,836.47,356,831,404.29,773.43,414.44,668.82Z" />
                <path d="M605.29,564.66c-13.7-24.65-26.67-47.88-39.55-71.17-16.78-30.35-34.08-60.44-49.92-91.28-6.11-11.9-12.89-16-26.59-15.43-39,1.55-71-27-75.3-64.89-4.5-39.72,19.72-73.68,58.71-82.33a73.84,73.84,0,0,1,86.28,50.07c9.09,29.32,1.42,55.22-19.75,77.9q37.2,67.46,74.16,134.41c2.32,4.2,4.89,8.27,8.24,13.9,55.72-23.54,110.53-21.33,163.25,7.84,39.8,22,67.87,54.87,84.07,97.53C901,705.86,867.4,801.51,789.22,848c-72.62,43.18-173.91,31.06-224-27.59l23.19-24.25c82,54.35,153.57,53.42,210.18-1.92C848.56,745.43,858.1,667.17,821,608c-22.84-36.4-55.64-58.66-97.71-67.72C680.92,531.15,642.76,542.91,605.29,564.66Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconWebhook;
