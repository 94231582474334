import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconDelete: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M500,48C250.37,48,48,250.37,48,500S250.37,952,500,952,952,749.63,952,500,749.63,48,500,48Zm0,806.57c-195.87,0-354.57-158.7-354.57-354.57S304.13,145.43,500,145.43,854.57,304.13,854.57,500,695.87,854.57,500,854.57Z" />
                <path d="M500,48C250.37,48,48,250.37,48,500S250.37,952,500,952,952,749.63,952,500,749.63,48,500,48Zm0,806.57c-195.87,0-354.57-158.7-354.57-354.57S304.13,145.43,500,145.43,854.57,304.13,854.57,500,695.87,854.57,500,854.57Z" />
                <path d="M702.9,640.14,562.76,500,702.9,359.86a44.4,44.4,0,0,0,0-62.76h0a44.4,44.4,0,0,0-62.76,0L500,437.24,359.86,297.1a44.4,44.4,0,0,0-62.76,0h0a44.4,44.4,0,0,0,0,62.76L437.24,500,297.1,640.14a44.4,44.4,0,0,0,0,62.76h0a44.4,44.4,0,0,0,62.76,0L500,562.76,640.14,702.9a44.4,44.4,0,0,0,62.76,0h0A44.4,44.4,0,0,0,702.9,640.14Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconDelete;
