import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconExternalLink: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M393.39,925.09c-76.16,0-152.33.44-228.48-.12-69.55-.52-118.08-34.72-148.16-96.24C8,810.89,4.32,791.43,4.26,771.57,4,705.4,4.1,639.24,4.1,573.08c0-91-.36-182,.2-273,.46-75.75,60.76-143,135.8-152.66a214.16,214.16,0,0,1,26.9-1.75q191.24-.17,382.48,0a31.21,31.21,0,0,1,11.67,1.92c7.13,2.94,9.67,9.23,9.71,16.6.07,11,.1,22,0,33-.11,12.81-6.76,19.33-19.55,19.33q-192.74,0-385.48,0c-37.47,0-64.43,17.3-82.1,49.73C78.1,276.62,75.53,288,75.1,299.82,75,302,75,304.16,75,306.32q0,229,0,458c0,37.39,17.88,63.86,50.15,81.26,12,6.45,25.12,8.69,38.61,8.7q230,0,460,.11c49.48.07,89.48-42.13,88.9-89.1-.71-57.66-.17-115.33-.16-173,0-15.47,6-21.54,21.44-21.56,10.17,0,20.34-.1,30.5,0,12.31.15,18.9,6.74,18.91,18.93,0,59,.14,118,0,177-.17,53.7-23.5,95.79-65.64,127.92-28,21.38-60.18,30.57-95.23,30.54Q507.88,925,393.39,925.09Z"/>
                <path d="M838.21,321.34c-1.38,1.28-2.6,2.35-3.75,3.5q-177.81,177.8-355.6,355.6c-9.63,9.64-19,9.65-28.58,0q-29.85-29.9-59.73-59.75c-9.92-9.91-10-19.05-.14-29Q537.28,444.89,684.15,298l66.6-66.6c-2-1.21-4.2-2-5.69-3.49q-46-45.85-91.93-91.88c-16.92-17-14.77-41.95,4.52-55.27,7-4.86,15-6,23.29-6q81,.06,162,0c38.33,0,76.66.07,115,0,12.66,0,23,4.33,30.86,14.46A34.7,34.7,0,0,1,996,111.07q0,141,0,282c0,15.74-7.85,27.24-22.13,33.27-14.48,6.12-27.75,2.74-38.89-8.41q-46.32-46.29-92.6-92.63C841.05,324,839.72,322.77,838.21,321.34Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconExternalLink;
