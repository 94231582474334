import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconTransview: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M148.28,491,94.55,653.69H25.48L201.24,136.36h80.6L458.37,653.69H387L331.73,491ZM269.56,21.22l80.59,87.5h-56.8L245,55.76h-1.54l-49.12,53h-54.5l78.29-87.5Zm48.35,417.55-50.66-148.9c-11.51-33.78-19.18-64.48-26.86-94.41h-1.54c-7.67,30.7-16.11,62.17-26.09,93.64L162.1,438.77Z" />
                <path d="M582.92,351.48c14.94.68,28.4,7.74,37.57,22.18a9,9,0,0,1,1,6.58q-8.09,27.83-16.8,55.53a10.2,10.2,0,0,1-5,5.45,46.74,46.74,0,0,1-62.17-60.49C544.65,363.05,561.88,351.45,582.92,351.48Z" />
                <path d="M707.57,321.05c-20.34.79-37.08-9.67-44.53-26a44.63,44.63,0,0,1,8-49C683.4,233,702.74,228.32,719,234.73a7.48,7.48,0,0,1,3.9,4c6.49,18.07,12.67,36.23,19.19,54.29,1.18,3.28.61,5.69-1.28,8.36C732,313.85,720,320.37,707.57,321.05Z" />
                <path d="M458.91,722.66a41.88,41.88,0,0,1,41.44,34.26,13.64,13.64,0,0,1-.34,6.44q-4.44,13.8-9.4,27.45a14.66,14.66,0,0,1-3.77,5.76,42.22,42.22,0,1,1-27.93-73.88Z" />
                <path d="M704.63,353.24c17.27,1,31.47,8.9,39.5,25.76s6.06,33.18-5.58,47.63-26.93,19.14-45,15.76c-3-.57-4.43-2.19-5.32-5-6.47-20.69-13.19-41.3-19.49-62.05a8.8,8.8,0,0,1,1.7-7.14C679.18,358.34,690.42,353.68,704.63,353.24Z" />
                <path d="M629,280.54c-.59,15.09-8.89,29.88-25.9,38.7s-33.87,7.28-50-3.2c-3.28-2.13-3.91-4.06-2.5-8,8.81-25,17.27-50.17,25.69-75.34,1.18-3.53,3-4.44,6.51-4.32C608.35,229.17,628.94,250.53,629,280.54Z" />
                <path d="M825.76,602.22c4.49,1,9.07,1.73,13.44,3.1,7.26,2.28,12.39,6.3,14.39,14.58,2.36,10,6.44,19.54,9.41,29.39a13.51,13.51,0,0,1,0,8.06,39.93,39.93,0,0,1-47.19,24.78c-19.41-4.79-32.89-24.18-30.29-43.59A41.37,41.37,0,0,1,825.76,602.22Z" />
                <path d="M822.64,724.29c19.18-.11,33.92,9.6,40.29,24.83a40.78,40.78,0,0,1-8.86,44.71,40.29,40.29,0,0,1-44.31,8.46,10.38,10.38,0,0,1-5.12-5.36q-8-22.26-15.2-44.79a10,10,0,0,1,.76-7.31C798,731.51,809.85,724.75,822.64,724.29Z" />
                <path d="M502.81,645c-1,16.46-9.08,30.27-25.79,37.81s-32.37,4.79-46.67-6.58c-2.72-2.16-3.4-4.22-2.18-7.73,7.56-21.83,14.79-43.8,22.34-65.62a6.23,6.23,0,0,1,4.28-3.25C479.42,596,503.1,617.64,502.81,645Z" />
                <path d="M443.84,934c-22.07-7.16-36.29-28.41-34.54-50.56,1.76-22.8,18.64-42.14,40.56-45.38,6.79-1,13.91.11,20.86.44,3.23.17,4.08,1.48,2.88,5-7.8,22.73-15.29,45.57-22.82,68.39C448.34,919.12,446.19,926.41,443.84,934Z" />
                <path d="M560.36,567c-24.26-10.16-36.58-38.25-28.05-63.24s35.58-40,60.68-33.05Z" />
                <path d="M803.55,474.08q16.32,47.84,32.62,95.58c-17.95,8.56-47.68-6.28-57.56-28.42C767.21,515.94,778.76,484.32,803.55,474.08Z" />
                <path d="M850.57,932.16c-11-32.34-21.87-64.4-32.76-96.5,22.22-5.32,48,9.83,56.55,33C883.21,892.58,873,920.39,850.57,932.16Z" />
                <path d="M959.64,931.51c-20.79,8.11-47.87-5.75-56.3-28.5-8.73-23.62,3.48-51.34,26.33-59.33Q944.67,887.6,959.64,931.51Z" />
                <path d="M731.54,564.68q-16.38-48.33-32.74-96.57c21.43-4.22,46.42,11.57,54.55,34.2C761.74,525.61,752,553.71,731.54,564.68Z" />
                <path d="M460.14,574.82c5.54-16.31,11-32.47,16.47-48.59s10.87-31.94,16.31-47.86c14.15,6.26,24,33.22,19.54,53.64C507.2,556.34,484.74,575,460.14,574.82Z" />
                <path d="M659.91,653.74H747.1c-.67,16.54-20.77,33.58-40.71,34.6C684,689.49,662.68,673.64,659.91,653.74Z" />
                <path d="M337.73,935.17q14.68-43.36,29.32-86.53c15.57,9,23.19,34.05,16.47,53.81S355.75,937.49,337.73,935.17Z" />
                <path d="M538.67,653.77h84.15c-1.21,16.48-20.9,32.62-40.43,33.31C561.76,687.81,541.36,672.43,538.67,653.77Z" />
                <path d="M752,734H655.38c11-15.79,25.31-24.72,43.87-26C721.29,706.38,738.5,715.42,752,734Z" />
                <path d="M533,733.84c12-16.66,27.36-25.54,47.29-25.71,20.32-.16,36,8.75,48.31,25.71Z" />
                <path d="M975.23,977.68H903.69c2.44-2.77,4.18-5.16,6.31-7.13,16.21-15,34.85-19.06,55.74-11.87a9,9,0,0,1,4.52,4.66C972.21,967.87,973.55,972.67,975.23,977.68Z" />
                <path d="M894.24,739.84q13.08,38.31,26.11,76.52a1.53,1.53,0,0,1-.16.43C893.71,805.75,879.11,767.67,894.24,739.84Z" />
                <path d="M323.36,977.6c2.54-7.67,5-15,7.38-22.28,19.19-2.8,41.35,7.09,49.89,22.28Z" />
                <path d="M795.79,451.55c-26.36-11.44-39.82-51.39-26.29-77.4C778.25,400,787,425.58,795.79,451.55Z" />
                <path d="M709.79,199.79c1.43,4,2.74,7.68,4.05,11.37-11.21,4.8-35.34-1-45.82-11.37Z" />
                <path d="M437.85,951.49c-2.79,8.43-5.14,15.84-7.76,23.14-.46,1.3-2,3.07-3.16,3.12-6.46.29-13,.14-20.37.14C414.11,965.23,424.22,957,437.85,951.49Z" />
                <path d="M582.67,214.25c2-5.24,2.49-11.15,5.76-13.77,2.71-2.21,8.54-.65,13-.68,5.67,0,11.34,0,17.1.48C608.32,209.14,596.57,213.68,582.67,214.25Z" />
                <path d="M523,389.65c3.13,21.18-2.74,39.34-18.35,54.09Q513.87,416.74,523,389.65Z" />
                <path d="M398,757.56q4.28,29.19-16.87,49.89Z" />
                <path d="M880,977.62c-14,1-14,1-17.92-10.93-1.11-3.35-2.06-6.76-3.38-11.14A62,62,0,0,1,880,977.62Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconTransview;
