import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconStateShareImage: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M569.62,539.55l-63.09,66.72c-24.13,25.52-63.54,26.06-76.68-15.42l-57.11-182.8c-6.81-21.81-22.51-36-39.88-36h0c-21.24,0-39.35,21-42.78,49.61L250.71,749.81C248.37,769.32,259.4,787,273.88,787H725.25c19.27,0,30.34-29.92,18.9-51.08L645.79,554C627.87,520.85,593.48,514.33,569.62,539.55Z"/>
                <circle cx="650.67" cy="296.93" r="98.11"/>
                <path d="M988.84,536.46c-71,0-81,43-81,74,0,27,4,54,8,81s8,55,8,82c0,101-63,137-145,137h-21v-55h17c56-1,80-31,80-87,0-24-4-47-7-72-4-24-8-49-8-76,0-64,28-98,72-109v-2c-44-12-72-44-72-108,0-27,4-51,8-76,3-24,7-48,7-71,0-54-21-85-79-86h-18v-55h20c85,0,146,39,146,131,0,28-4,55-8,83s-8,56-8,84c0,35,10,74,81,74Z"/>
                <path d="M7.54,487.46c71,0,81-43,81-74,0-27-4-54-8-81s-8-55-8-82c0-101,63-137,145-137h21v55h-17c-56,1-80,31-80,87,0,24,4,47,7,72,4,24,8,49,8,76,0,64-28,98-72,109v2c44,12,72,44,72,108,0,27-4,51-8,76-3,24-7,48-7,71,0,54,21,85,79,86h18v55h-20c-85,0-146-39-146-131,0-28,4-55,8-83s8-56,8-84c0-35-10-74-81-74Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconStateShareImage;
