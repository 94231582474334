import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconClassProtectedAccessor: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <polygon points="230.86 612.79 251.1 607.82 498.12 547.2 505.94 545.29 500.1 555.74 300.53 913.34 235.99 634.9 230.86 612.79"/>
                <polygon points="483.43 39.72 623.22 642.75 525.81 666.66 500.1 555.74 505.94 545.29 498.12 547.2 435.56 277.31 251.1 607.82 230.86 612.79 235.99 634.9 169.46 754.11 71.29 778.2 483.43 39.72"/>
                <path d="M800.2,584.2a16.55,16.55,0,0,1,3.83-1.64c40.16-10.16,80.25-20,120.4-30.17,3.31-.83,4.05.42,4.06,4.1,0,29.25.1,58.36.18,87.5-.19,28.66.36,56.9-.3,85.8-.22,9.71-2.69,21-5.56,31.75C902.4,838.26,862.72,901.22,804,949.44a22.38,22.38,0,0,1-3.07,2.11C796.61,954,792,955,787.73,956c-60.85,15.06-97.87-10.08-113.64-70.82a39.6,39.6,0,0,1-1.08-9.59q-.33-85.92-.61-171.88c0-3.69,1.07-5.58,4-8.25q59.91-54.09,119.78-108.35A23.79,23.79,0,0,1,800.2,584.2Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconClassProtectedAccessor;
