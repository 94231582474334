import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconLink: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M904.55,455.06,724.71,634.84c-99.26,99.31-260.28,99.31-359.55,0-15.64-15.6-27.87-33.17-38.61-51.29L410.11,500c4-4,8.87-6.3,13.56-9,5.78,19.73,15.86,38.42,31.39,53.94,49.56,49.61,130.22,49.54,179.76,0L814.61,365.17c49.6-49.61,49.6-130.25,0-179.81s-130.18-49.57-179.79,0l-63.93,64C519,229.16,463.12,223.72,408.71,231.7L544.94,95.47c99.32-99.29,260.29-99.29,359.61,0S1003.81,355.77,904.55,455.06ZM429.13,750.68l-64,64c-49.57,49.54-130.23,49.54-179.8,0a127.33,127.33,0,0,1,0-179.84l179.8-179.78a127.21,127.21,0,0,1,179.78,0c15.48,15.49,25.58,34.17,31.41,53.88,4.71-2.74,9.56-5,13.53-8.94l83.55-83.52c-10.67-18.19-23-35.7-38.61-51.31-99.25-99.3-260.28-99.3-359.58,0L95.47,545c-99.29,99.33-99.29,260.28,0,359.6s260.29,99.27,359.58,0L591.32,768.3C536.88,776.32,481,770.84,429.13,750.68Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconLink;
