// #region module
export enum SizeTypes {
    small = 'small',
    normal = 'normal',
    large = 'large',
}


export enum HorizontalPositions {
    left = 'left',
    right = 'right',
}


export enum VerticalPositions {
    top = 'top',
    bottom = 'bottom',
}
// #endregion module
