import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconPaintBrush: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M356.5,337.5,665.81,647.12c-6.1,6-12.68,12.33-19.18,18.78-20.69,20.54-40.83,41.68-62.14,61.56-33.68,31.42-81.65,31.06-115.59-.29-12.45-11.51-24.15-23.84-36.21-35.77-20.09-19.88-44.05-19.24-64.73.09-19.16,17.91-32.55,39-42.38,63.15-11.66,28.64-24.38,57-42.54,82.21-32.26,44.8-74.26,76.34-128.64,89.08-11.06,2.59-23.31,2.55-34.64,1-21.9-3-36.53-18.5-41.22-40.12-4.79-22.1-1.29-43.4,6.77-63.91,21.17-53.89,58.63-93.35,108.94-121.23,25.86-14.34,52.84-26.16,80.42-36.74a32.63,32.63,0,0,0,10.11-5.6c10.63-9.56,21.58-18.91,31.16-29.47,14.57-16.05,14.08-39.49-.72-55.48-11.39-12.32-23.56-23.92-35.42-35.8-18.66-18.69-28.8-40.87-27.21-67.62,1.3-21.78,10.51-40.16,25.7-55.5,25.36-25.62,50.95-51,76.46-76.51A18.34,18.34,0,0,1,356.5,337.5Zm-173.82,522c23.24,0,43.32-20.05,43.4-43.25s-20.32-43.82-43.55-43.75c-23.06.06-43.36,20.34-43.44,43.37C139,839.27,159.25,859.53,182.68,859.48Z" />
                <path d="M471.61,344.74l-61.15-61.47c4.72-4.85,10.47-10.79,16.25-16.7Q515,176.39,603.23,86.19c7.11-7.27,15.08-12.94,25.45-14C642.77,70.77,648,77.06,659.94,89a70.59,70.59,0,0,1,7.54,6.75c80.82,80.87,159.63,162,240.5,242.86,20.76,20.75,20.9,45.07.26,65.71q-95,95-190.09,190c-1.12,1.12-2.28,2.19-3.49,3.35l-60.92-61" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconPaintBrush;
