import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconInterface: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M378.14,366.34c30.38,17.54,23.94,42.17-14.37,55s-94,9-124.38-8.51-23.93-42.15,14.38-55S347.77,348.81,378.14,366.34Z"/>
                <path d="M569,302.39c26.5,15.3,25,36-1.28,49.54a123.47,123.47,0,0,1,13.51,10.5q10.14,9.26,12.12,15.47a12.49,12.49,0,0,1-1.83,11.57,26,26,0,0,1-9.53,8.31,102.58,102.58,0,0,1-14,5.72l-87.31,29.26q-23.35,7.82-19.49,21.35a28.75,28.75,0,0,0,9.54,14.4,66.54,66.54,0,0,0,9.71,7.09,31.41,31.41,0,0,0,3,1.53,24,24,0,0,1,2.38,1.19q3.06,1.77,3.36,3.57c.27,1.6-2.22,3.29-7.43,5l-9.37,3.13c-3.69,1.24-6.73,1.7-9.09,1.38a18.53,18.53,0,0,1-6.81-2.37c-2.18-1.26-6.06-3.19-11.61-5.78a145.32,145.32,0,0,0-20.08-7.38,157.21,157.21,0,0,0-25.3-5.62,85.4,85.4,0,0,0-21.07-.43q-9.19,1.13-13.19,2t-10,2.92l-84,28.15c-3.89,1.3-6.85,2.25-8.9,2.84s-5.93,1.4-11.66,2.44a88.22,88.22,0,0,1-16.1,1.53A151.87,151.87,0,0,1,214.75,508a157.27,157.27,0,0,1-24.66-5.62,208,208,0,0,1-32.34-13C121,497,75.08,492.13,48.52,476.79c-30.38-17.53-23.94-42.16,14.37-55s94-9,124.38,8.51c25.56,14.76,25,34.53,1.41,48.08a136.67,136.67,0,0,0,20.57,8.18,50.41,50.41,0,0,0,30.2-.46l85-28.48q8.94-3,15.89-4.6a167.14,167.14,0,0,1,20.46-2.86,134.26,134.26,0,0,1,31,1.24,253.55,253.55,0,0,1,38.31,8.89q-7.12-8.66-8.33-16t.9-11.79q2.13-4.43,8.86-8.56a65.1,65.1,0,0,1,10.47-5.54q3.74-1.41,10.73-3.75l86.5-29q14.63-4.9,9-12.4c-2-2.73-6.34-6.41-12.85-11-36,6.47-79.59,1.44-105.19-13.34-30.37-17.53-23.94-42.15,14.39-55S538.64,284.86,569,302.39Z"/>
                <path d="M760.61,587.16c30.38,17.54,23.94,42.16-14.38,55s-94,9-124.37-8.51-23.94-42.15,14.38-55S730.24,569.63,760.61,587.16Z"/>
                <path d="M951.49,523.21c30.37,17.54,23.93,42.16-14.39,55s-94,9-124.37-8.51c-25.87-14.93-25-35-.53-48.56q-16.87-8.53-27.49-10.77-15.35-3.22-28.31,1.12l-77.57,26c-.8.27-2.1.75-3.88,1.44s-3.08,1.19-3.89,1.46A156.16,156.16,0,0,1,654.68,545a182.1,182.1,0,0,1-20.75,2.81q-13.41,1.22-31.13-1.35a264.42,264.42,0,0,1-38.27-8.76q6.72,8.79,8.1,16t-.74,11.66q-2.11,4.41-8.52,8.52A59.72,59.72,0,0,1,553,579.49q-3.93,1.46-10.9,3.79L456.5,612q-7.83,2.63-9.91,5.29T452,628a75.17,75.17,0,0,0,12.1,9.91c36.1-6.6,80-1.6,105.68,13.25,30.37,17.54,23.94,42.16-14.38,55s-94,9-124.38-8.51c-26.44-15.27-25-35.91,1.14-49.47a134.2,134.2,0,0,1-17.35-13q-10-9.17-12-15.37a12.71,12.71,0,0,1,1.67-11.6,25.23,25.23,0,0,1,9.39-8.33,103.52,103.52,0,0,1,14-5.7l88.45-29.63q16.07-5.38,17.84-13.9t-5.43-15.34a162.54,162.54,0,0,0-15.44-12.85q-8.24-6-8.15-6.35c-.41-1.42,1.63-2.88,6.14-4.39L522,508q7.14-2.38,10.76-1.35t13.48,6.16a134.93,134.93,0,0,0,24.19,9.35,155.91,155.91,0,0,0,25.38,5.6,83.79,83.79,0,0,0,21.09.35Q626.05,527,630,526t10-3l84.65-28.37c3.9-1.3,6.87-2.25,8.94-2.85s5.88-1.38,11.48-2.38a88.8,88.8,0,0,1,16.3-1.44,155.21,155.21,0,0,1,20.16,1.79,157.43,157.43,0,0,1,24.7,5.61A212.34,212.34,0,0,1,843,510.52C879.57,503.11,925.09,508,951.49,523.21Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconInterface;
