import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconPolicies: React.FC<PluridIconProperties> = (properties) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M471.83,464.7c-17.67,15.39-20.64,43.26-3.74,59.55,9.68,9.34,11.63,18.6,10.74,30.39-.43,5.76-.08,11.57-.07,17.36,0,9.83-.08,19.67,0,29.51.13,14.22,6.32,20,21.09,20s21.21-5.77,21.31-19.86c.13-19.39.24-38.78-.1-58.16-.11-6.26,1.5-10.42,6.62-14.62,18.26-15,20.09-41.72,4.81-59.75S489.7,449.14,471.83,464.7Z"/>
                <path d="M491.21,223.86c-53.15,5.58-94.54,45.88-97.32,97.69-.3,5.71-.52,12-.67,18.65h.31v.09h10.22l202.66,0C606,326.66,605.32,313,602,300.09,589.31,251.52,540,218.73,491.21,223.86Z"/>
                <path d="M891.94,134.3c0-9.81-2.25-14.3-12.36-17.15-122.8-34.65-245.41-70-368.21-104.64a46.32,46.32,0,0,0-23.95.13C365,47.18,242.83,82.32,120.46,116.9c-9.06,2.56-12.42,5.91-12.4,15.74.34,152.68.19,305.37.39,458.06a110.77,110.77,0,0,0,3.23,27.16c47.65,185.73,160.56,309.42,346.64,362.8,16.07,4.6,34.27,10.67,49.65,7.42,180-38,301.79-144.5,364.83-317.21,8.86-24.26,16.51-50.41,17.27-75.9,2.26-75.85.81-151.8.81-227.71h.84C891.72,289.61,891.54,212,891.94,134.3Zm-143,524.58c-.06,42.93-24.7,75.4-64.22,84.74a102.19,102.19,0,0,1-23.22,2.47q-162.48.21-324.95,0c-42.74-.06-76-27.25-84.17-68.41a93.77,93.77,0,0,1-1.28-18.14Q251,509.26,251,358.94c0-14.2,4.43-18.61,18.73-18.64,17.35,0,34.7,0,53.54,0v-.09h.28c.19-8.5.5-17,1-25.47,4.74-81.74,75.6-151.94,157-160.08,91.75-9.17,179.59,55.27,193,151.1a253.38,253.38,0,0,1,2.12,34.57h52c16.45,0,20.25,3.71,20.25,19.67Q749,509.45,748.93,658.88Z"/>
            </svg>
        </PluridIcon>
    );
}


export default PluridIconPolicies;
