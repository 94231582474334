import React from 'react';

import PluridIcon from '../../PluridIcon';

import {
    PluridIconProperties,
} from '../../interfaces';



const PluridIconTag: React.FC<PluridIconProperties> = (
    properties,
) => {
    return (
        <PluridIcon
            {...properties}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <path d="M979,404.76c-5,18.58-8.85,37.54-15.17,55.65a214.33,214.33,0,0,1-52,82.12q-161.6,161-322.68,322.54c-25.82,25.84-56.31,35.67-91.16,23.56-13-4.53-25.86-13-35.73-22.72Q340.21,745.37,219.43,623.53c-37.36-37.52-37.21-89,.17-126.41C328.8,387.72,437.67,278,547.71,169.44c42.25-41.69,95-62.13,154.46-62.86,61.39-.75,122.8.53,184.18-.38,50-.74,86,32,91.7,74.39.07.56.62,1.06,1,1.59ZM865.21,304.61c-.33-46.86-39.46-85.65-85.46-84.7-46.68,1-84.32,38.85-84.48,85-.15,46.38,38.7,85.1,85.27,85C827.44,389.8,865.55,351.4,865.21,304.61Z" />
                <path d="M394.07,883.85c-35.21,17.33-72.07,12-100.15-15.79Q170.08,745.27,47.35,621.38c-36-36.48-34.74-87.33,1.43-123.61q164.4-164.86,329.43-329.11c38.06-37.64,85.74-56.6,139-61.53,1.5-.14,3,0,6.8,0-3.91,4.07-6.51,6.88-9.22,9.58Q308.12,323.32,101.48,529.91c-2.43,2.42-4.87,4.83-7.27,7.28-15.24,15.54-15.28,31,.15,46.43Q216.63,706,339,828.26q24.13,24.13,48.27,48.27C389.4,878.68,391.39,881,394.07,883.85Z" />
            </svg>
        </PluridIcon>
    );
}


export default PluridIconTag;
